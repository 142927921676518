// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".R3dSD{width:200px;padding:16px;background:rgba(255,255,255,.6);border:1px dashed #307de4;border-radius:10px;box-shadow:0 20px 30px rgba(63,142,247,.1);-webkit-backdrop-filter:blur(2px);backdrop-filter:blur(2px)}.a97X3{padding-bottom:6px;color:#3f8ef7}.a97X3::part(typography){font-weight:500;font-size:10px;line-height:20px;letter-spacing:5%;text-transform:uppercase}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestleAsia/processPhases/contentCards/inputCard/InputCard.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CACA,YAAA,CACA,+BAAA,CACA,yBAAA,CACA,kBAAA,CACA,0CAAA,CACA,iCAAA,CAAA,yBAAA,CAGF,OACE,kBAAA,CACA,aAAA,CAEA,yBACE,eAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,wBAAA","sourcesContent":[".card {\n  width: 200px;\n  padding: 16px;\n  background: rgba(#ffffff, 60%);\n  border: 1px dashed #307de4;\n  border-radius: 10px;\n  box-shadow: 0 20px 30px rgba(#3f8ef7, 10%);\n  backdrop-filter: blur(2px);\n}\n\n.title {\n  padding-bottom: 6px;\n  color: #3f8ef7;\n\n  &::part(typography) {\n    font-weight: 500;\n    font-size: 10px;\n    line-height: 20px;\n    letter-spacing: 5%;\n    text-transform: uppercase;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "R3dSD",
	"title": "a97X3"
};
export default ___CSS_LOADER_EXPORT___;
