import { WppIconArrow } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/no7Beauty/components/phaseCard/PhaseCard.module.scss'

interface Props {
  isActive: boolean
  index: number
  title: string
  description: string
  link?: string
  onPhaseHover: (index: number) => void
  variant: number
  onClick: () => void
  refference: any
}

export const PhaseCard = ({
  isActive,
  index,
  title,
  description,
  onPhaseHover,
  variant,
  onClick,
  refference,
}: Props) => {
  return (
    <div
      className={clsx(styles.phasee, styles[`phaseVariant${variant}`], {
        [styles.activePhasee]: isActive,
      })}
      onMouseOver={() => onPhaseHover(index)}
      onClick={onClick}
      ref={refference}
    >
      <div
        className={clsx(styles.phaseeIndex, {
          [styles.activePhaseeIndex]: isActive,
        })}
      >
        {index + 1}
      </div>
      <Flex direction="column" justify="end" className={styles.fullheight}>
        {isActive ? (
          <div>
            <h2 className={styles.activePhaseeTitle}>{title}</h2>
            <p className={styles.phaseeDescription}>{description}</p>
          </div>
        ) : (
          <Flex direction="column" justify="end" className={styles.fullheight}>
            <Flex justify="between" align="end" gap={4} className={styles.fullheight}>
              <div className={styles.phaseeTitle}>{title}</div>
              <Flex justify="center" align="center" className={styles.iconButtonn}>
                <WppIconArrow direction="right" color={variant === 3 ? '#fff' : '#05054B'} />
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
    </div>
  )
}
