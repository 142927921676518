// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VeQIe{margin-bottom:8px}.ILEA4{color:var(--wpp-grey-color-000)}.xKrpo{margin-bottom:8px}.Byawk{max-width:163px}.tg8_A{width:24px;height:24px;background-color:var(--wpp-grey-color-900);border-radius:50%}.gxAKY{background-color:#0f62fd !important}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/ibm/processPhases/processCard/rolesResponsibilitiesCard/RolesResponsibilitiesCard.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CAGF,OACE,+BAAA,CAGF,OACE,iBAAA,CAGF,OACE,eAAA,CAGF,OACE,UAAA,CACA,WAAA,CACA,0CAAA,CACA,iBAAA,CAGF,OACE,mCAAA","sourcesContent":[".descriptionProcessTitle {\n  margin-bottom: 8px;\n}\n\n.whiteText {\n  color: var(--wpp-grey-color-000);\n}\n\n.roleWrapper {\n  margin-bottom: 8px;\n}\n\n.roleName {\n  max-width: 163px;\n}\n\n.letterDisc {\n  width: 24px;\n  height: 24px;\n  background-color: var(--wpp-grey-color-900);\n  border-radius: 50%;\n}\n\n.blueBg {\n  background-color: #0f62fd !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"descriptionProcessTitle": "VeQIe",
	"whiteText": "ILEA4",
	"roleWrapper": "xKrpo",
	"roleName": "Byawk",
	"letterDisc": "tg8_A",
	"blueBg": "gxAKY"
};
export default ___CSS_LOADER_EXPORT___;
