// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CEL6z{width:100%;height:calc(100vh - var(--wpp-os-header-height));background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:center bottom;background-size:contain}.H7ALx{position:relative;max-width:var(--wpp-os-content-max-width);height:100%;margin:0 auto}.QNeZg{position:absolute;top:52px;right:40px}.GPy8H{height:100%}.IxOMb{width:100%;max-width:660px;margin-top:-80px}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/lego/LegoProcess.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,gDAAA,CACA,wDAAA,CACA,2BAAA,CACA,iCAAA,CACA,uBAAA,CAGF,OACE,iBAAA,CACA,yCAAA,CACA,WAAA,CACA,aAAA,CAGF,OACE,iBAAA,CACA,QAAA,CACA,UAAA,CAGF,OACE,WAAA,CAGF,OACE,UAAA,CACA,eAAA,CACA,gBAAA","sourcesContent":[".root {\n  width: 100%;\n  height: calc(100vh - var(--wpp-os-header-height));\n  background-image: url('./assets/bg.png');\n  background-repeat: no-repeat;\n  background-position: center bottom;\n  background-size: contain;\n}\n\n.page {\n  position: relative;\n  max-width: var(--wpp-os-content-max-width);\n  height: 100%;\n  margin: 0 auto;\n}\n\n.logo {\n  position: absolute;\n  top: 52px;\n  right: 40px;\n}\n\n.svgContainer {\n  height: 100%;\n}\n\n.svgWrapper {\n  width: 100%;\n  max-width: 660px;\n  margin-top: -80px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "CEL6z",
	"page": "H7ALx",
	"logo": "QNeZg",
	"svgContainer": "GPy8H",
	"svgWrapper": "IxOMb"
};
export default ___CSS_LOADER_EXPORT___;
