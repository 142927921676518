// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tcsk4{min-width:auto;min-height:auto;overflow:hidden}.tcsk4 #app-process-builder-root{min-width:auto;min-height:auto}.tcsk4 [data-testid=\"small-screen-boundary\"]{display:none}.tcsk4 header{display:none}", "",{"version":3,"sources":["webpack://./src/pages/customWidget/CustomWidgetRoutes.module.scss"],"names":[],"mappings":"AAAA,OACE,cAAA,CACA,eAAA,CACA,eAAA,CAEA,iCACE,cAAA,CACA,eAAA,CAGF,6CACE,YAAA,CAGF,cACE,YAAA","sourcesContent":[".root {\n  min-width: auto;\n  min-height: auto;\n  overflow: hidden;\n\n  :global(#app-process-builder-root) {\n    min-width: auto;\n    min-height: auto;\n  }\n\n  :global([data-testid='small-screen-boundary']) {\n    display: none;\n  }\n\n  header {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "tcsk4"
};
export default ___CSS_LOADER_EXPORT___;
