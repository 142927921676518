// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/BootsSharp-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/BootsSharp-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/BootsSharp-Bold.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-weight:300;font-family:BootsSharp;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff\")}@font-face{font-weight:400;font-family:BootsSharp;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\")}@font-face{font-weight:700;font-family:BootsSharp;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff\")}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/no7Beauty/fonts.css"],"names":[],"mappings":"AAAA,WACE,eAAA,CACA,sBAAA,CACA,0DAAA,CAGF,WACE,eAAA,CACA,sBAAA,CACA,0DAAA,CAGF,WACE,eAAA,CACA,sBAAA,CACA,0DAAA","sourcesContent":["@font-face {\n  font-weight: 300;\n  font-family: BootsSharp;\n  src: url('./assets/fonts/BootsSharp-Light.woff2') format('woff');\n}\n\n@font-face {\n  font-weight: 400;\n  font-family: BootsSharp;\n  src: url('./assets/fonts/BootsSharp-Regular.woff2') format('woff');\n}\n\n@font-face {\n  font-weight: 700;\n  font-family: BootsSharp;\n  src: url('./assets/fonts/BootsSharp-Bold.woff2') format('woff');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
