interface BoootsProcessDataI {
  items: { title: string; description: string }[]
  link: string
}
export const BootsProcessData: BoootsProcessDataI[] = [
  {
    items: [{ title: 'Project Kick-Off', description: 'Moving from Briefing into Project Initiation' }],
    link: '/custom/project-kick-off',
  },
  {
    items: [{ title: 'Strategic Response', description: 'A single, aligned view of the audience' }],
    link: '/custom/strategic-response',
  },
  {
    items: [
      { title: 'BIG idea development', description: 'A response to Brief with Creative ways to solve our Connect' },
      { title: 'Connections Planning', description: 'Collaborate to create industry leading solutions' },
    ],
    link: '/custom/connection-planning',
  },
  {
    items: [{ title: 'Activation', description: 'Allow cross discipline review of the work' }],
    link: '/custom/activation',
  },
  {
    items: [
      {
        title: 'Evaluation',
        description: 'A single, clear understanding and view of campaign performance',
      },
    ],
    link: '/custom/evaluation',
  },
]
