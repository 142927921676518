// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QYQwm{position:relative;max-width:var(--wpp-os-content-max-width);margin:0 auto;padding:61px 38px 38px}.KVqzR{margin-bottom:24px}.OvVcQ{display:block;margin-bottom:16px}.UaKbm{width:94.5px}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/tigerBrands/TigerBrandsProcess.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,yCAAA,CACA,aAAA,CACA,sBAAA,CAGF,OACE,kBAAA,CAGF,OACE,aAAA,CACA,kBAAA,CAGF,OACE,YAAA","sourcesContent":[".root {\n  position: relative;\n  max-width: var(--wpp-os-content-max-width);\n  margin: 0 auto;\n  padding: 61px 38px 38px;\n}\n\n.header {\n  margin-bottom: 24px;\n}\n\n.title {\n  display: block;\n  margin-bottom: 16px;\n}\n\n.logo {\n  width: 94.5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "QYQwm",
	"header": "KVqzR",
	"title": "OvVcQ",
	"logo": "UaKbm"
};
export default ___CSS_LOADER_EXPORT___;
