import { WppActionButton, WppButton } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import CloseIcon from 'pages/customProcess/components/no7Beauty/assets/Close.png'
import styles from 'pages/customProcess/components/no7Beauty/components/phasePopup/PhasePopup.module.scss'

export interface PhasePopupConfig {
  title: string
  description?: string
  list: { title: string; items: string[]; css?: object; listCss?: object }[]
  button: { title: string; link: string }
}

export const PhasePopup = ({
  targetRef,
  variant,
  config,
  isOpen,
  onClose,
}: {
  targetRef: any
  variant?: number
  config: PhasePopupConfig
  isOpen?: boolean
  onClose: () => void
}) => {
  const getStyles = useCallback(() => {
    const targetRect = targetRef?.getBoundingClientRect()

    return {
      width: targetRect?.width,
      left: targetRect?.left,
      top: targetRect?.top,
    }
  }, [targetRef])

  const [inlineStyles, setStyles] = useState<any>(getStyles())

  const updateStyles = useCallback(() => {
    setStyles(getStyles())
  }, [getStyles])

  targetRef?.addEventListener('transitionend', updateStyles)

  useEffect(() => () => {
    targetRef?.removeEventListener('transitionend', updateStyles)
  })

  return isOpen ? (
    <div className={styles.phasePopupWrapper}>
      <div className={clsx(styles.phasePopupRoot, styles[`popupVariant${variant}`])} style={inlineStyles}>
        <WppActionButton onClick={onClose} className={styles.closeBtn}>
          <img src={CloseIcon} alt="" />
        </WppActionButton>
        <Flex className={styles.phasePopupContent} direction="column" gap={17}>
          <h2 className={styles.phasePopupTitle}>{config.title}</h2>
          <p className={styles.phasePopupDescription}>{config.description}</p>

          <Flex direction="row" gap={12} className={styles.phasePopupListsContent}>
            {config.list.map((listSection, index) => (
              <div key={index} style={listSection?.css} className={styles.phasePopupListWrap}>
                <h3 className={styles.phasePopupListTitle}>{listSection.title}</h3>

                <ul className={styles.phasePopupList} style={listSection?.listCss}>
                  {listSection.items.map((listItem, itemIndex) => (
                    <li key={itemIndex}>{listItem}</li>
                  ))}
                </ul>
              </div>
            ))}
          </Flex>

          <Link to={config.button?.link} className={styles.phasePopupBtnLink}>
            <WppButton className={styles.phasePopupBtn}>{config.button?.title}</WppButton>
          </Link>
        </Flex>
      </div>
      <div className={styles.phasePopupBlur} onClick={onClose} />
    </div>
  ) : null
}
