// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pOM8U:hover{cursor:pointer}.vvQM0{opacity:0;transition:opacity .35s ease}.vvQM0:hover{cursor:pointer}.cDIDH{opacity:1}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/bp/processSvg/ProcessSvg.module.scss"],"names":[],"mappings":"AACE,aACE,cAAA,CAIJ,OACE,SAAA,CACA,4BAAA,CAEA,aACE,cAAA,CAIJ,OACE,SAAA","sourcesContent":[".cursorPointer {\n  &:hover {\n    cursor: pointer;\n  }\n}\n\n.phase {\n  opacity: 0;\n  transition: opacity 0.35s ease;\n\n  &:hover {\n    cursor: pointer;\n  }\n}\n\n.phaseActive {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cursorPointer": "pOM8U",
	"phase": "vvQM0",
	"phaseActive": "cDIDH"
};
export default ___CSS_LOADER_EXPORT___;
