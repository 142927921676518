// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nEudz::part(content){margin-left:0}.nEudz.oC09Y::part(icon){display:none}.nEudz.oC09Y::part(title-wrapper){pointer-events:none}.WNAVg{display:grid;grid-gap:20px 12px;grid-template-columns:repeat(2, 1fr);width:100%}", "",{"version":3,"sources":["webpack://./src/components/form/formPalette/FormPalette.module.scss"],"names":[],"mappings":"AACE,sBACE,aAAA,CAIA,yBACE,YAAA,CAGF,kCACE,mBAAA,CAKN,OACE,YAAA,CACA,kBAAA,CACA,oCAAA,CACA,UAAA","sourcesContent":[".root {\n  &::part(content) {\n    margin-left: 0;\n  }\n\n  &.disableExpanding {\n    &::part(icon) {\n      display: none;\n    }\n\n    &::part(title-wrapper) {\n      pointer-events: none;\n    }\n  }\n}\n\n.container {\n  display: grid;\n  grid-gap: 20px 12px;\n  grid-template-columns: repeat(2, 1fr);\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "nEudz",
	"disableExpanding": "oC09Y",
	"container": "WNAVg"
};
export default ___CSS_LOADER_EXPORT___;
