import { useRef } from 'react'
import { useUpdate, usePrevious } from 'react-use'

type CloseCompleteHandler = (e: Event) => any

export const useKeepUnmountedOnClose = <
  K extends 'onWppSideModalCloseComplete' | 'onWppModalCloseComplete',
  P extends { open?: boolean } & {
    [key in K]?: P[K]
  },
>(
  props: P,
  onCloseCompletePropName: K,
) => {
  const { open, [onCloseCompletePropName]: onCloseCompleteHandler, ...rest } = props

  const prevOpen = usePrevious(open)
  const isClosingRef = useRef(false)
  const update = useUpdate()

  if (prevOpen && !open) {
    isClosingRef.current = true
  }

  const shouldRender = open || isClosingRef.current

  const closeCompleteHandler: CloseCompleteHandler = e => {
    const handler = onCloseCompleteHandler as CloseCompleteHandler

    handler?.(e)
    isClosingRef.current = false
    update()
  }

  return [
    shouldRender,
    {
      ...rest,
      open,
      [onCloseCompletePropName]: closeCompleteHandler as P[K],
    },
  ] as const
}
