// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".w6vjy{padding:16px}.A0QsG{background:linear-gradient(262deg, #ff5656 40.82%, #fdc46e 94.8%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}._ao4V{margin-top:-120px}.vaOZO{margin-top:0}.soQHM{margin-top:140px}.Ksyrt{margin-top:24px;padding:16px 16px 40px}.H79qQ{width:330px}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestleHealth/processPhases/pages/campaingAnalysis/CampaingAnalysis.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CAGF,OACE,iEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,iBAAA,CAGF,OACE,YAAA,CAGF,OACE,gBAAA,CAGF,OACE,eAAA,CACA,sBAAA,CAGF,OACE,WAAA","sourcesContent":[".inputsCard {\n  padding: 16px;\n}\n\n.adverityTagColor {\n  background: linear-gradient(262deg, #ff5656 40.82%, #fdc46e 94.8%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.regularCard1Wrapper {\n  margin-top: -120px;\n}\n\n.regularCard2Wrapper {\n  margin-top: 0;\n}\n\n.regularCard3Wrapper {\n  margin-top: 140px;\n}\n\n.outputsCard {\n  margin-top: 24px;\n  padding: 16px 16px 40px;\n}\n\n.mainCard {\n  width: 330px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsCard": "w6vjy",
	"adverityTagColor": "A0QsG",
	"regularCard1Wrapper": "_ao4V",
	"regularCard2Wrapper": "vaOZO",
	"regularCard3Wrapper": "soQHM",
	"outputsCard": "Ksyrt",
	"mainCard": "H79qQ"
};
export default ___CSS_LOADER_EXPORT___;
