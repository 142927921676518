// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".E_Lsy{width:23.5%;padding:19px 16px;background-color:#fff0f1}.yzyw5{margin-bottom:8px}.SNyv7{min-width:16px;margin-top:1px}.W3MBZ{white-space:pre-wrap}.W3MBZ::part(typography){color:#000;font-size:10px;font-family:\"IBM Plex Mono\",monospace;line-height:16px}.ko5OL{font-weight:600}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/ibmNew/processPhases/components/responsibilitiesInfo/ResponsibilitiesInfo.module.scss"],"names":[],"mappings":"AAEA,OACE,WAAA,CACA,iBAAA,CACA,wBAAA,CAGF,OACE,iBAAA,CAGF,OACE,cAAA,CACA,cAAA,CAGF,OACE,oBAAA,CAEA,yBACE,UAAA,CACA,cAAA,CACA,qCAAA,CACA,gBAAA,CAIJ,OACE,eAAA","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;600&display=swap');\n\n.card {\n  width: 23.5%;\n  padding: 19px 16px;\n  background-color: #fff0f1;\n}\n\n.section {\n  margin-bottom: 8px;\n}\n\n.icon {\n  min-width: 16px;\n  margin-top: 1px;\n}\n\n.text {\n  white-space: pre-wrap;\n\n  &::part(typography) {\n    color: #000000;\n    font-size: 10px;\n    font-family: 'IBM Plex Mono', monospace;\n    line-height: 16px;\n  }\n}\n\n.bold {\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "E_Lsy",
	"section": "yzyw5",
	"icon": "SNyv7",
	"text": "W3MBZ",
	"bold": "ko5OL"
};
export default ___CSS_LOADER_EXPORT___;
