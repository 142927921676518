// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tJAL2{display:flex;flex-direction:column;align-items:center;justify-content:center;height:100%}.tJAL2>*+*{margin-top:16px}.uhMub{display:flex;flex-direction:column;align-items:center;justify-content:center;width:600px;text-align:center}.uhMub>*+*{margin-top:16px}.R75yO{color:var(--wpp-grey-color-700)}.ikwaF>*+*{margin-left:16px}", "",{"version":3,"sources":["webpack://./src/components/baseErrorState/BaseErrorState.module.scss"],"names":[],"mappings":"AAWA,OAVE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CAUA,WAAA,CARA,WACE,eAAA,CAUJ,OAhBE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CAgBA,WAAA,CACA,iBAAA,CAfA,WACE,eAAA,CAiBJ,OACE,+BAAA,CAIA,WACE,gBAAA","sourcesContent":["@mixin flexColumnCenter {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  > * + * {\n    margin-top: 16px;\n  }\n}\n\n.root {\n  @include flexColumnCenter;\n\n  height: 100%;\n}\n\n.textWrapper {\n  @include flexColumnCenter;\n\n  width: 600px;\n  text-align: center;\n}\n\n.subTitle {\n  color: var(--wpp-grey-color-700);\n}\n\n.actions {\n  > * + * {\n    margin-left: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "tJAL2",
	"textWrapper": "uhMub",
	"subTitle": "R75yO",
	"actions": "ikwaF"
};
export default ___CSS_LOADER_EXPORT___;
