import { useOs } from '@wpp-open/react'
import { Navigate, useSearchParams } from 'react-router-dom'

import { Tenants } from 'constants/tenants'
import { TigerBrandsProcess } from 'pages/customProcess//components/tigerBrands/TigerBrandsProcess'
import { AldiProcess } from 'pages/customProcess/components/aldi/AldiProcess'
import { AllianzProcess } from 'pages/customProcess/components/allianzNew/AllianzProcess'
import { ASMLProcess } from 'pages/customProcess/components/asml/ASMLProcess'
import { AudibleProcess } from 'pages/customProcess/components/audible/AudibleProcess'
import { AxaProcess } from 'pages/customProcess/components/axa/AxaProcess'
import { BeiersdorfProcess } from 'pages/customProcess/components/beiersdorf/BeiersdorfProcess'
import { BootsProcess } from 'pages/customProcess/components/boots/BootsProcess'
import { BPProcess } from 'pages/customProcess/components/bp/BPProcess'
import { CKProcess } from 'pages/customProcess/components/CK/CKProcess'
import { CocaColaProcess } from 'pages/customProcess/components/cocaCola/CocaColaProcess'
import { CocaColaChinaProcess } from 'pages/customProcess/components/cocaColaChina/CocaColaChinaProcess'
import { EasyJetProcess } from 'pages/customProcess/components/easyjet/EasyJetProcess'
import { FerreroProcess } from 'pages/customProcess/components/ferreroNew/FerreroProcess'
import { FordMotorChinaProcess } from 'pages/customProcess/components/fordMotorChina/FordMotorChinaProcess'
import { FossilProcess } from 'pages/customProcess/components/fossilNew/FossilProcess'
import { GoogleProcess } from 'pages/customProcess/components/google/GoogleProcess'
import { HenkelProcess } from 'pages/customProcess/components/henkel/HenkelProcess'
import { IbmProcess } from 'pages/customProcess/components/ibmNew/IbmProcess'
import { IkeaProcess } from 'pages/customProcess/components/ikeaNew/IkeaProcess'
import { LegoProcess } from 'pages/customProcess/components/lego/LegoProcess'
import { LoccitaneProcess } from 'pages/customProcess/components/loccitane/LoccitaneProcess'
import { LorealProcess } from 'pages/customProcess/components/loreal/LorealProcess'
import { LvmhProcess } from 'pages/customProcess/components/lvmh/LvmhProcess'
import { MattrassProcess } from 'pages/customProcess/components/mattrass/MattrassProcess'
import { MediaMarktSaturnProcess } from 'pages/customProcess/components/mediaMarktSaturn/MediaMarktSaturnProcess'
import { NestleProcess } from 'pages/customProcess/components/nestle/NestleProcess'
import { NestleAsiaProcess } from 'pages/customProcess/components/nestleAsia/NestleProcess'
import { NestleChinaProcess } from 'pages/customProcess/components/nestleChina/NestleChinaProcess'
import { NestleHealthProcess } from 'pages/customProcess/components/nestleHealth/NestleHealthProcess'
import { NetflixProcess } from 'pages/customProcess/components/netflix/NetflixProcess'
import { No7BeautyProcess } from 'pages/customProcess/components/no7Beauty/No7BeautyProcess'
import { OrangeProcess } from 'pages/customProcess/components/orange/OrangeProcess'
import { PaneraProcess } from 'pages/customProcess/components/panera/PaneraProcess'
import { PerrigoProcess } from 'pages/customProcess/components/perrigo/PerrigoProcess'
import { PfizerProcess } from 'pages/customProcess/components/pfizer/PfizerProcess'
import { ProcterAndGambleProcess } from 'pages/customProcess/components/procterAndGamble/ProcterAndGambleProcess'
import { ProcterAndGambleEUProcess } from 'pages/customProcess/components/procterAndGambleEU/ProcterAndGambleEUProcess'
import { SEINProcess } from 'pages/customProcess/components/sein/SEINProcess'
import { ShellProcess } from 'pages/customProcess/components/shell/ShellProcess'
import { SpotifyProcess } from 'pages/customProcess/components/spotify/SpotifyProcess'
import { TapestryProcess } from 'pages/customProcess/components/tapestry/TapestryProcess'
import { TflProcess } from 'pages/customProcess/components/tfl/TflProcess'
import { TommyProcess } from 'pages/customProcess/components/tommy/TommyProcess'
import { TuiProcess } from 'pages/customProcess/components/tui/TuiProcess'
import { UnileverProcess } from 'pages/customProcess/components/unilever/UnileverProcess'
import { VodafoneProcess } from 'pages/customProcess/components/vodafone/VodafoneProcess'
import { WarnerBrosProcess } from 'pages/customProcess/components/warnerBros/WarnerBrosProcess'
import { YUMChinaProcess } from 'pages/customProcess/components/yumChina/YUMChinaProcess'
import { routesManager } from 'utils/routesManager'

export const ProcessCase = () => {
  const {
    osContext: { tenant },
  } = useOs()
  const [searchParams] = useSearchParams()
  const nameQuery = searchParams.get('name')

  switch (nameQuery || tenant.name) {
    case Tenants.Audible:
      return <AudibleProcess />
    case Tenants.Pfizer:
      return <PfizerProcess />
    case Tenants.EasyJet:
      return <EasyJetProcess />
    case Tenants.Fossil:
      return <FossilProcess />
    case Tenants.No7Beauty:
      return <No7BeautyProcess />
    case Tenants.Nestle:
    case Tenants.NestleUK:
    case Tenants.NestleProd:
      return <NestleProcess />
    case Tenants.NestleChina:
      return <NestleChinaProcess />
    case Tenants.NestleAsia:
    case Tenants.NestleAOA:
    case Tenants.NestleMEAP:
      return <NestleAsiaProcess />
    case Tenants.NestleHealthScience:
      return <NestleHealthProcess />
    case Tenants.Beiersdorf:
      return <BeiersdorfProcess />
    case Tenants.AXA:
      return <AxaProcess />
    case Tenants.Orange:
      return <OrangeProcess />
    case Tenants.Aldi:
      return <AldiProcess />
    case Tenants.Perrigo:
      return <PerrigoProcess />
    case Tenants.Panera:
      return <PaneraProcess />
    case Tenants.Allianz:
      return <AllianzProcess />
    case Tenants.Spotify:
      return <SpotifyProcess />
    case Tenants.Tapestry:
      return <TapestryProcess />
    case Tenants.Unilever:
      return <UnileverProcess />
    case Tenants.Google:
      return <GoogleProcess />
    case Tenants.Mattressfirm:
      return <MattrassProcess />
    case Tenants.IBM:
      return <IbmProcess />
    case Tenants.Ikea:
    case Tenants.IKEA:
      return <IkeaProcess />
    case Tenants.Vodafone:
      return <VodafoneProcess />
    case Tenants.Boots:
      return <BootsProcess />
    case Tenants.TCCC:
      return <CocaColaProcess />
    case Tenants.TCCC_China:
      return <CocaColaChinaProcess />
    case Tenants.Ferrero:
      return <FerreroProcess />
    case Tenants.WarnerBrothers:
      return <WarnerBrosProcess />
    case Tenants.LVMH:
    case Tenants.LVMHAPAC:
      return <LvmhProcess />
    case Tenants.Samsung:
      return <SEINProcess />
    case Tenants.Henkel:
      return <HenkelProcess />
    case Tenants.ProcterAndGamble:
      return <ProcterAndGambleProcess />
    case Tenants.PGWorkspace:
      return <ProcterAndGambleEUProcess />
    case Tenants.ASML:
      return <ASMLProcess />
    case Tenants.Netflix:
      return <NetflixProcess />
    case Tenants.Loccitane:
      return <LoccitaneProcess />
    case Tenants.FordMotorChina:
      return <FordMotorChinaProcess />
    case Tenants.TigerBrands:
      return <TigerBrandsProcess />
    case Tenants.Loreal:
    case Tenants.LOreal:
      return <LorealProcess />
    case Tenants.MediaMarktSaturn:
      return <MediaMarktSaturnProcess />
    case Tenants.BritishPetroleum:
      return <BPProcess />
    case Tenants.Lego:
      return <LegoProcess />
    case Tenants.YumChina:
      return <YUMChinaProcess />
    case Tenants.Shell:
      return <ShellProcess />
    case Tenants.TUI:
      return <TuiProcess />
    case Tenants.TFL:
      return <TflProcess />
    case Tenants.Tommy:
      return <TommyProcess />
    case Tenants.CK:
      return <CKProcess />
    default:
      return <Navigate to={routesManager.notFound.url} />
  }
}
