// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".m9idJ{min-width:calc(25% - 24px)}.sLuTA{width:80px;height:80px;border-radius:50%}.zk2fT{background:linear-gradient(91deg, #c660ef 0.76%, #6d5fe7 97.5%)}.X8UxV{background:linear-gradient(91deg, #7e6af4 0%, #3362f3 98.81%)}.zONQo{background:linear-gradient(91deg, #3362f3 1.06%, #88ee97 100%)}.rBaoH{background:linear-gradient(91deg, #1dd460 -18.7%, #eed461 99.84%)}.sPRGp{background:linear-gradient(91deg, #e9c528 0%, #c660ef 95.94%)}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/spotify/processPhases/processCard/descriptionCard/DescriptionCard.module.scss"],"names":[],"mappings":"AAAA,OACE,0BAAA,CAGF,OACE,UAAA,CACA,WAAA,CACA,iBAAA,CAGF,OACE,+DAAA,CAGF,OACE,6DAAA,CAGF,OACE,8DAAA,CAGF,OACE,iEAAA,CAGF,OACE,6DAAA","sourcesContent":[".minWidth {\n  min-width: calc(25% - 24px);\n}\n\n.iconContainer {\n  width: 80px;\n  height: 80px;\n  border-radius: 50%;\n}\n\n.discoverIconBg {\n  background: linear-gradient(91deg, #c660ef 0.76%, #6d5fe7 97.5%);\n}\n\n.decisionIconBg {\n  background: linear-gradient(91deg, #7e6af4 0%, #3362f3 98.81%);\n}\n\n.designIconBg {\n  background: linear-gradient(91deg, #3362f3 1.06%, #88ee97 100%);\n}\n\n.deliverIconBg {\n  background: linear-gradient(91deg, #1dd460 -18.7%, #eed461 99.84%);\n}\n\n.adaptIconBg {\n  background: linear-gradient(91deg, #e9c528 0%, #c660ef 95.94%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"minWidth": "m9idJ",
	"iconContainer": "sLuTA",
	"discoverIconBg": "zk2fT",
	"decisionIconBg": "X8UxV",
	"designIconBg": "zONQo",
	"deliverIconBg": "rBaoH",
	"adaptIconBg": "sPRGp"
};
export default ___CSS_LOADER_EXPORT___;
