// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[8].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[8].use[2]!../../../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[8].use[3]!../../../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[8].use[4]!./fonts.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EbI34{min-height:100vh;padding:34px 0 48px;background-color:#f5f5f5}.tX3rv{padding-bottom:48px}.ZmORI{max-width:500px;color:#05054b;font-weight:400;font-size:28px;font-family:BootsSharp,sans-serif;font-style:normal;line-height:36px;font-feature-settings:\"clig\" off,\"liga\" off}.X6jBn{color:#05054b;font-weight:400;font-size:28px;font-family:BootsSharp,sans-serif;font-style:normal;line-height:36px;font-feature-settings:\"clig\" off,\"liga\" off}.r4xDm{width:100%}.B_ds_{width:200px}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/boots/BootsProcess.module.scss"],"names":[],"mappings":"AAEA,OACE,gBAAA,CACA,mBAAA,CACA,wBAAA,CAGF,OACE,mBAAA,CAGF,OACE,eAAA,CACA,aAAA,CACA,eAAA,CACA,cAAA,CACA,iCAAA,CACA,iBAAA,CACA,gBAAA,CACA,2CAAA,CAGF,OACE,aAAA,CACA,eAAA,CACA,cAAA,CACA,iCAAA,CACA,iBAAA,CACA,gBAAA,CACA,2CAAA,CAGF,OACE,UAAA,CAGF,OACE,WAAA","sourcesContent":["@import url('./fonts.css');\n\n.page {\n  min-height: 100vh;\n  padding: 34px 0 48px;\n  background-color: #f5f5f5;\n}\n\n.accordionSection {\n  padding-bottom: 48px;\n}\n\n.sectionTitle1 {\n  max-width: 500px;\n  color: #05054b;\n  font-weight: 400;\n  font-size: 28px;\n  font-family: BootsSharp, sans-serif;\n  font-style: normal;\n  line-height: 36px; /* 128.571% */\n  font-feature-settings: 'clig' off, 'liga' off;\n}\n\n.sectionTitle2 {\n  color: #05054b;\n  font-weight: 400;\n  font-size: 28px;\n  font-family: BootsSharp, sans-serif;\n  font-style: normal;\n  line-height: 36px; /* 128.571% */\n  font-feature-settings: 'clig' off, 'liga' off;\n}\n\n.schemeImg {\n  width: 100%;\n}\n\n.logo {\n  width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": "EbI34",
	"accordionSection": "tX3rv",
	"sectionTitle1": "ZmORI",
	"sectionTitle2": "X6jBn",
	"schemeImg": "r4xDm",
	"logo": "B_ds_"
};
export default ___CSS_LOADER_EXPORT___;
