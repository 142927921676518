// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Hf2jH{padding:16px 16px 40px}.X6aXG{background:linear-gradient(262deg, #ff5656 40.82%, #fdc46e 94.8%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.C98fv{margin-top:-90px}.Nn9jy{margin-top:60px}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestleHealth/processPhases/pages/mediaPlan/MediaPlan.module.scss"],"names":[],"mappings":"AAAA,OACE,sBAAA,CAGF,OACE,iEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,gBAAA,CAGF,OACE,eAAA","sourcesContent":[".inputsCard {\n  padding: 16px 16px 40px;\n}\n\n.brand {\n  background: linear-gradient(262deg, #ff5656 40.82%, #fdc46e 94.8%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.regularCard1Wrapper {\n  margin-top: -90px;\n}\n\n.regularCard2Wrapper {\n  margin-top: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsCard": "Hf2jH",
	"brand": "X6aXG",
	"regularCard1Wrapper": "C98fv",
	"regularCard2Wrapper": "Nn9jy"
};
export default ___CSS_LOADER_EXPORT___;
