// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ySm4K{margin-top:-90px}.OKSVG{margin-top:70px}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/bp/processPhases/pages/assembleBP/AssembleBP.module.scss"],"names":[],"mappings":"AAAA,OACE,gBAAA,CAGF,OACE,eAAA","sourcesContent":[".regularCard1 {\n  margin-top: -90px;\n}\n\n.regularCard2 {\n  margin-top: 70px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"regularCard1": "ySm4K",
	"regularCard2": "OKSVG"
};
export default ___CSS_LOADER_EXPORT___;
