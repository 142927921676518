// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WxfMc{margin-top:-75px}.jUdxf{padding:16px 16px 40px}.gss8n{background:linear-gradient(31.81deg, #6e7bff 1.51%, #4aa3ff 85.25%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.usVeu{margin-top:-150px}.CIww6{margin-top:-100px}.CzCir{margin-top:56px}.Dd5_F{padding:16px}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestleChina/processPhases/pages/mediaActivation/MediaActivation.module.scss"],"names":[],"mappings":"AAAA,OACE,gBAAA,CAGF,OACE,sBAAA,CAGF,OACE,mEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,iBAAA,CAGF,OACE,iBAAA,CAGF,OACE,eAAA,CAGF,OACE,YAAA","sourcesContent":[".inputCardsWrapper {\n  margin-top: -75px;\n}\n\n.inputsCard {\n  padding: 16px 16px 40px;\n}\n\n.redmillTagColor {\n  background: linear-gradient(31.81deg, #6e7bff 1.51%, #4aa3ff 85.25%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.regularCard2Wrapper {\n  margin-top: -150px;\n}\n\n.regularCard3Wrapper {\n  margin-top: -100px;\n}\n\n.regularCard4Wrapper {\n  margin-top: 56px;\n}\n\n.outputsCard {\n  padding: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputCardsWrapper": "WxfMc",
	"inputsCard": "jUdxf",
	"redmillTagColor": "gss8n",
	"regularCard2Wrapper": "usVeu",
	"regularCard3Wrapper": "CIww6",
	"regularCard4Wrapper": "CzCir",
	"outputsCard": "Dd5_F"
};
export default ___CSS_LOADER_EXPORT___;
