import { WppIconArrow } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/customProcess/components/boots/components/phaseCard/PhaseCard.module.scss'

interface Props {
  isActive: boolean
  index: number
  items: { title: string; description: string }[]
  link?: string
  onPhaseHover: (index: number) => void
  variant: number
  onClick: (title: string) => void
  refference: any
}

export const PhaseCard = ({ isActive, index, items, onPhaseHover, variant, onClick, refference }: Props) => {
  return (
    <div
      className={clsx(styles.phasee, styles[`phaseVariant${variant}`], {
        [styles.activePhasee]: isActive,
      })}
      onClick={() => (items.length === 1 ? onClick(items[0].title) : null)}
      onMouseOver={() => onPhaseHover(index)}
      ref={refference}
    >
      <div className={clsx(styles.activePhaseeIndex, !isActive && styles.blackIndex)}>{index + 1}</div>
      <Flex direction="column" justify="end" className={styles.fullheight}>
        {isActive ? (
          items.map(({ title, description }) => (
            <Flex direction="column" key={title} onClick={() => onClick(title)}>
              <h2 className={styles.activePhaseeTitle}>{title}</h2>
              <p className={styles.phaseeDescription}>{description}</p>
            </Flex>
          ))
        ) : (
          <Flex direction="column" justify="end" className={styles.fullheight}>
            <Flex justify="between" align="end" gap={4} className={styles.fullheight}>
              <div className={styles.phaseeTitle}>
                {items.map(({ title }, index) => (
                  <Flex key={title} direction="column">
                    <div>{title}</div>
                    {index === 0 && items.length > 1 && <div style={{ textAlign: 'center' }}>+</div>}
                  </Flex>
                ))}
              </div>
              <Flex justify="center" align="center" className={styles.iconButtonn}>
                <WppIconArrow direction="right" color="#05054B" />
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
    </div>
  )
}
