// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cfZqS{position:relative;background-repeat:no-repeat;background-position:center;background-size:cover;transition:margin .5s ease}.cfZqS.ex0Yo{margin-right:600px}.v3ZqZ{position:absolute;z-index:-1;width:100%;height:100%}.Up4JW{position:absolute;bottom:50px;left:50%;z-index:9;transform:translateX(-50%)}.Qv1m4{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}", "",{"version":3,"sources":["webpack://./src/pages/phaseBuilder/PhaseBuilder.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,2BAAA,CACA,0BAAA,CACA,qBAAA,CACA,0BAAA,CAEA,aACE,kBAAA,CAIJ,OACE,iBAAA,CACA,UAAA,CACA,UAAA,CACA,WAAA,CAGF,OACE,iBAAA,CACA,WAAA,CACA,QAAA,CACA,SAAA,CACA,0BAAA,CAGF,OACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA","sourcesContent":[".root {\n  position: relative;\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n  transition: margin 0.5s ease;\n\n  &.editMode {\n    margin-right: 600px;\n  }\n}\n\n.bgColor {\n  position: absolute;\n  z-index: -1;\n  width: 100%;\n  height: 100%;\n}\n\n.editBtn {\n  position: absolute;\n  bottom: 50px;\n  left: 50%;\n  z-index: 9;\n  transform: translateX(-50%);\n}\n\n.loader {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "cfZqS",
	"editMode": "ex0Yo",
	"bgColor": "v3ZqZ",
	"editBtn": "Up4JW",
	"loader": "Qv1m4"
};
export default ___CSS_LOADER_EXPORT___;
