// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sHVX_{padding:16px 16px 40px}.Fvj6O{padding:16px 16px 64px}.BQ27l{color:#04eeaa}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestleAsia/processPhases/pages/bigBriefIdea/bigBriefIdea.module.scss"],"names":[],"mappings":"AAAA,OACE,sBAAA,CAGF,OACE,sBAAA,CAGF,OACE,aAAA","sourcesContent":[".inputsCard {\n  padding: 16px 16px 40px;\n}\n\n.outputsCard {\n  padding: 16px 16px 64px;\n}\n\n.imagineTagColor {\n  color: #04eeaa;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsCard": "sHVX_",
	"outputsCard": "Fvj6O",
	"imagineTagColor": "BQ27l"
};
export default ___CSS_LOADER_EXPORT___;
