// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".b7Q2k{width:200px;background:rgba(63,142,255,.9);border:.5px solid #307de4;border-radius:10px;box-shadow:0 20px 30px rgba(63,142,247,.2);-webkit-backdrop-filter:blur(2px);backdrop-filter:blur(2px)}.Lm2Fi{display:block;padding-bottom:6px;color:var(--wpp-grey-color-000);opacity:.7}.Lm2Fi::part(typography){font-size:10px;line-height:20px;text-transform:uppercase}.K0Qa4{color:var(--wpp-grey-color-000)}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestle/processPhases/contentCards/inputsOutpustCard/InputsOutputsCard.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CACA,8BAAA,CACA,yBAAA,CACA,kBAAA,CACA,0CAAA,CACA,iCAAA,CAAA,yBAAA,CAGF,OACE,aAAA,CACA,kBAAA,CACA,+BAAA,CACA,UAAA,CAEA,yBACE,cAAA,CACA,gBAAA,CACA,wBAAA,CAIJ,OACE,+BAAA","sourcesContent":[".card {\n  width: 200px;\n  background: rgba(#3f8eff, 90%);\n  border: 0.5px solid #307de4;\n  border-radius: 10px;\n  box-shadow: 0 20px 30px rgba(#3f8ef7, 20%);\n  backdrop-filter: blur(2px);\n}\n\n.title {\n  display: block;\n  padding-bottom: 6px;\n  color: var(--wpp-grey-color-000);\n  opacity: 0.7;\n\n  &::part(typography) {\n    font-size: 10px;\n    line-height: 20px;\n    text-transform: uppercase;\n  }\n}\n\n.text {\n  color: var(--wpp-grey-color-000);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "b7Q2k",
	"title": "Lm2Fi",
	"text": "K0Qa4"
};
export default ___CSS_LOADER_EXPORT___;
