import { useState, useRef, useCallback } from 'react'

import { Flex } from 'components/common/flex/Flex'
import MomentsImg from 'pages/customProcess/components/no7Beauty/assets/5Moments.png'
import Logo from 'pages/customProcess/components/no7Beauty/assets/logo-main.png'
import { PhaseCard } from 'pages/customProcess/components/no7Beauty/components/phaseCard/PhaseCard'
import { PhasePopup } from 'pages/customProcess/components/no7Beauty/components/phasePopup/PhasePopup'
import styles from 'pages/customProcess/components/no7Beauty/No7BeautyProcess.module.scss'
import { No7BeautyProcessData } from 'pages/customProcess/components/no7Beauty/No7BeautyProcessData'
import { phasePopupsConfig } from 'pages/customProcess/components/no7Beauty/phasePopupsConfig'

export const No7BeautyProcess = () => {
  const [activePhaseIndex, setActivePhaseIndex] = useState(0)
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const phaseRefs = useRef<Record<number, any>>({
    0: null,
    1: null,
    2: null,
    3: null,
    4: null,
  })

  const handlePhaseHover = (index: number) => {
    if (index === activePhaseIndex) {
      return
    }
    setActivePhaseIndex(index)
  }

  const openPopup = useCallback(() => {
    setIsPopupOpen(true)
  }, [])

  const closePopup = useCallback(() => {
    setIsPopupOpen(false)
  }, [])

  const fillRef = useCallback(
    (index: number) => (ref: any) => {
      if (!phaseRefs.current) return
      phaseRefs.current[index] = ref
    },
    [phaseRefs],
  )

  return (
    <div className={styles.page}>
      <div>
        <section className={styles.accordionSection}>
          <Flex direction="column" gap={54}>
            <Flex justify="between" align="center">
              <h1 className={styles.sectionTitle1}>
                5 Moments in our process to drive further integration and optimise it
              </h1>
              <img src={Logo} alt="" className={styles.logo} />
            </Flex>

            <Flex gap={4}>
              {No7BeautyProcessData.map((phase, index) => (
                <PhaseCard
                  isActive={activePhaseIndex === index}
                  index={index}
                  title={phase.title}
                  link={phase.link}
                  description={phase.description}
                  onPhaseHover={handlePhaseHover}
                  key={index}
                  variant={index + 1}
                  onClick={openPopup}
                  refference={fillRef(index)}
                />
              ))}
            </Flex>
          </Flex>

          {phaseRefs.current[activePhaseIndex] && (
            <PhasePopup
              targetRef={phaseRefs.current[activePhaseIndex]}
              variant={activePhaseIndex + 1}
              config={phasePopupsConfig[activePhaseIndex]}
              isOpen={isPopupOpen}
              onClose={closePopup}
            />
          )}
        </section>

        <section>
          <Flex direction="column" gap={24}>
            <h2 className={styles.sectionTitle2}>Moments mapped to the current process</h2>
            <img src={MomentsImg} alt="" className={styles.schemeImg} />
          </Flex>
        </section>
      </div>
    </div>
  )
}
