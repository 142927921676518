// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hBEbr{min-width:calc(25% - 24px)}.vEr4u{width:80px;height:80px;border-radius:50%}.qaZzb{background:linear-gradient(91deg, #d02670 0.76%, #ffafd2 97.5%)}.Gyciq{background:linear-gradient(271deg, rgba(138, 63, 252, 0.8) 0.12%, rgba(212, 187, 255, 0.8) 100%)}.ZbsNU{background:linear-gradient(91deg, rgba(0, 114, 195, 0.8) 1.06%, rgba(130, 207, 255, 0.8) 100%)}.NjGZh{background:linear-gradient(91deg, rgba(0, 125, 121, 0.8) -18.7%, rgba(61, 219, 217, 0.8) 99.84%)}.fM_qY{background:linear-gradient(91deg, rgba(25, 128, 56, 0.8) 0%, rgba(111, 220, 140, 0.8) 95.94%)}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/ibm/processPhases/processCard/descriptionCard/DescriptionCard.module.scss"],"names":[],"mappings":"AAAA,OACE,0BAAA,CAGF,OACE,UAAA,CACA,WAAA,CACA,iBAAA,CAGF,OACE,+DAAA,CAGF,OACE,gGAAA,CAGF,OACE,8FAAA,CAGF,OACE,gGAAA,CAGF,OACE,6FAAA","sourcesContent":[".minWidth {\n  min-width: calc(25% - 24px);\n}\n\n.iconContainer {\n  width: 80px;\n  height: 80px;\n  border-radius: 50%;\n}\n\n.discoverCardIconBg {\n  background: linear-gradient(91deg, #d02670 0.76%, #ffafd2 97.5%);\n}\n\n.decisionCardIconBg {\n  background: linear-gradient(271deg, rgb(138 63 252 / 80%) 0.12%, rgb(212 187 255 / 80%) 100%);\n}\n\n.designCardIconBg {\n  background: linear-gradient(91deg, rgb(0 114 195 / 80%) 1.06%, rgb(130 207 255 / 80%) 100%);\n}\n\n.deliverCardIconBg {\n  background: linear-gradient(91deg, rgb(0 125 121 / 80%) -18.7%, rgb(61 219 217 / 80%) 99.84%);\n}\n\n.adaptCardIconBg {\n  background: linear-gradient(91deg, rgb(25 128 56 / 80%) 0%, rgb(111 220 140 / 80%) 95.94%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"minWidth": "hBEbr",
	"iconContainer": "vEr4u",
	"discoverCardIconBg": "qaZzb",
	"decisionCardIconBg": "Gyciq",
	"designCardIconBg": "ZbsNU",
	"deliverCardIconBg": "NjGZh",
	"adaptCardIconBg": "fM_qY"
};
export default ___CSS_LOADER_EXPORT___;
