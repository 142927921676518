// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MZjpW{position:relative;z-index:1;pointer-events:auto}.MZjpW:hover{cursor:pointer}.dhk8E{opacity:0;transition:opacity .25s linear}.X_A35{opacity:1}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/lvmh/components/LvmhProcessSvg.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,SAAA,CACA,mBAAA,CAEA,aACE,cAAA,CAIJ,OACE,SAAA,CACA,8BAAA,CAGF,OACE,SAAA","sourcesContent":[".processPhase {\n  position: relative;\n  z-index: 1;\n  pointer-events: auto;\n\n  &:hover {\n    cursor: pointer;\n  }\n}\n\n.phaseDetails {\n  opacity: 0;\n  transition: opacity 0.25s linear;\n}\n\n.phaseDetailsActive {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"processPhase": "MZjpW",
	"phaseDetails": "dhk8E",
	"phaseDetailsActive": "X_A35"
};
export default ___CSS_LOADER_EXPORT___;
