// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zOhYg{margin-bottom:8px}.ks_pe{color:var(--wpp-grey-color-000)}.E2BB9 li{margin-left:22px;line-height:22px;list-style-type:disc}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/ibm/processPhases/pages/deliver/Deliver.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CAGF,OACE,+BAAA,CAIA,UACE,gBAAA,CACA,gBAAA,CACA,oBAAA","sourcesContent":[".descriptionProcessTitle {\n  margin-bottom: 8px;\n}\n\n.whiteText {\n  color: var(--wpp-grey-color-000);\n}\n\n.processList {\n  li {\n    margin-left: 22px;\n    line-height: 22px;\n    list-style-type: disc;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"descriptionProcessTitle": "zOhYg",
	"whiteText": "ks_pe",
	"processList": "E2BB9"
};
export default ___CSS_LOADER_EXPORT___;
