// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/bg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".O453F{position:relative;width:100%;height:calc(100vh - var(--wpp-os-header-height));overflow-y:hidden;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:center;background-size:cover}.iyZjF{width:100%;height:100%}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/yumChina/YUMChinaProcess.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,UAAA,CACA,gDAAA,CACA,iBAAA,CACA,wDAAA,CACA,2BAAA,CACA,0BAAA,CACA,qBAAA,CAGF,OACE,UAAA,CACA,WAAA","sourcesContent":[".root {\n  position: relative;\n  width: 100%;\n  height: calc(100vh - var(--wpp-os-header-height));\n  overflow-y: hidden;\n  background-image: url('./assets/bg.jpg');\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n}\n\n.svgContainer {\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "O453F",
	"svgContainer": "iyZjF"
};
export default ___CSS_LOADER_EXPORT___;
