// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e6GRB{margin-bottom:8px;color:var(--wpp-grey-color-000)}.myhg1{color:var(--wpp-grey-color-000)}._Q6lf{margin-bottom:8px}.K202E{max-width:163px}.Tj5KU{width:24px;height:24px;background-color:var(--wpp-grey-color-900);border-radius:50%}.axTdf{background-color:#1dd460}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/spotify/processPhases/processCard/rolesResponsibilitiesCard/RolesResponsibilitiesCard.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,+BAAA,CAGF,OACE,+BAAA,CAGF,OACE,iBAAA,CAGF,OACE,eAAA,CAGF,OACE,UAAA,CACA,WAAA,CACA,0CAAA,CACA,iBAAA,CAGF,OACE,wBAAA","sourcesContent":[".descriptionCardTitle {\n  margin-bottom: 8px;\n  color: var(--wpp-grey-color-000);\n}\n\n.whiteText {\n  color: var(--wpp-grey-color-000);\n}\n\n.roleWrapper {\n  margin-bottom: 8px;\n}\n\n.roleName {\n  max-width: 163px;\n}\n\n.letterDisc {\n  width: 24px;\n  height: 24px;\n  background-color: var(--wpp-grey-color-900);\n  border-radius: 50%;\n}\n\n.greenBg {\n  background-color: #1dd460;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"descriptionCardTitle": "e6GRB",
	"whiteText": "myhg1",
	"roleWrapper": "_Q6lf",
	"roleName": "K202E",
	"letterDisc": "Tj5KU",
	"greenBg": "axTdf"
};
export default ___CSS_LOADER_EXPORT___;
