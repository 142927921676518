// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".A7ZCS{margin-bottom:8px;color:var(--wpp-grey-color-000)}.cuq1i{color:var(--wpp-grey-color-000)}.JjY1i li{margin-left:22px;color:var(--wpp-grey-color-000);line-height:22px;list-style-type:disc}@media(height <= 840px){.uBGzy{margin-top:-55px}}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/spotify/processPhases/pages/adapt/Adapt.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CACA,+BAAA,CAGF,OACE,+BAAA,CAIA,UACE,gBAAA,CACA,+BAAA,CACA,gBAAA,CACA,oBAAA,CAKF,wBADF,OAEI,gBAAA,CAAA","sourcesContent":[".descriptionCardTitle {\n  margin-bottom: 8px;\n  color: var(--wpp-grey-color-000);\n}\n\n.whiteText {\n  color: var(--wpp-grey-color-000);\n}\n\n.list {\n  li {\n    margin-left: 22px;\n    color: var(--wpp-grey-color-000);\n    line-height: 22px;\n    list-style-type: disc;\n  }\n}\n\n.controls {\n  @media (height <= 840px) {\n    margin-top: -55px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"descriptionCardTitle": "A7ZCS",
	"whiteText": "cuq1i",
	"list": "JjY1i",
	"controls": "uBGzy"
};
export default ___CSS_LOADER_EXPORT___;
