// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DJZoT{display:flex}.Ue637{display:inline-flex}.PWU8f{flex-direction:column}.oWQam{align-items:flex-start}.Rl_IA{align-items:center}.QLSwy{align-items:flex-end}.GDCbs{justify-content:start}.FluAt{justify-content:center}.MsvgP{justify-content:flex-end}.IRWTn{justify-content:space-between}.h_iRR{justify-content:space-around}.g4Y4R{flex-wrap:nowrap}.Mjt4X{flex-wrap:wrap}", "",{"version":3,"sources":["webpack://./src/components/common/flex/Flex.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CAGF,OACE,mBAAA,CAGF,OACE,qBAAA,CAGF,OACE,sBAAA,CAGF,OACE,kBAAA,CAGF,OACE,oBAAA,CAGF,OACE,qBAAA,CAGF,OACE,sBAAA,CAGF,OACE,wBAAA,CAGF,OACE,6BAAA,CAGF,OACE,4BAAA,CAGF,OACE,gBAAA,CAGF,OACE,cAAA","sourcesContent":[".root {\n  display: flex;\n}\n\n.inline {\n  display: inline-flex;\n}\n\n.column {\n  flex-direction: column;\n}\n\n.align-start {\n  align-items: flex-start;\n}\n\n.align-center {\n  align-items: center;\n}\n\n.align-end {\n  align-items: flex-end;\n}\n\n.justify-start {\n  justify-content: start;\n}\n\n.justify-center {\n  justify-content: center;\n}\n\n.justify-end {\n  justify-content: flex-end;\n}\n\n.justify-between {\n  justify-content: space-between;\n}\n\n.justify-around {\n  justify-content: space-around;\n}\n\n.nowrap {\n  flex-wrap: nowrap;\n}\n\n.wrap {\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "DJZoT",
	"inline": "Ue637",
	"column": "PWU8f",
	"align-start": "oWQam",
	"align-center": "Rl_IA",
	"align-end": "QLSwy",
	"justify-start": "GDCbs",
	"justify-center": "FluAt",
	"justify-end": "MsvgP",
	"justify-between": "IRWTn",
	"justify-around": "h_iRR",
	"nowrap": "g4Y4R",
	"wrap": "Mjt4X"
};
export default ___CSS_LOADER_EXPORT___;
