// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".C7LnM{font-weight:600;font-size:28px;font-family:var(--wpp-font-family, sans-serif);pointer-events:none}.tjl1N{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/pages/processBuilder/templates/circular5/phase/Phase.module.scss"],"names":[],"mappings":"AAAA,OACE,eAAA,CACA,cAAA,CACA,8CAAA,CACA,mBAAA,CAGF,OACE,cAAA","sourcesContent":[".title {\n  font-weight: 600;\n  font-size: 28px;\n  font-family: var(--wpp-font-family, sans-serif);\n  pointer-events: none;\n}\n\n.isInteractive {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "C7LnM",
	"isInteractive": "tjl1N"
};
export default ___CSS_LOADER_EXPORT___;
