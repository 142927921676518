// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zelyC{margin-bottom:4px}.zelyC.MSkAT::part(button){background-color:var(--wpp-grey-color-300)}", "",{"version":3,"sources":["webpack://./src/components/form/formDimensions/FormDimensions.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CAEA,2BACE,0CAAA","sourcesContent":[".ratioButton {\n  margin-bottom: 4px;\n\n  &.isActive::part(button) {\n    background-color: var(--wpp-grey-color-300);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ratioButton": "zelyC",
	"isActive": "MSkAT"
};
export default ___CSS_LOADER_EXPORT___;
