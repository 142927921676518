import { WppTypography, WppButton } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { LegendSection } from 'pages/phaseBuilder/legendSideModal/legendSection/LegendSection'
import styles from 'pages/phaseBuilder/legendSideModal/LegendSideModal.module.scss'
import { PhaseFormConfig } from 'types/phase/phase'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  config: PhaseFormConfig
}

export const LegendSideModal = ({ isOpen, onClose, onCloseComplete, config }: Props) => {
  const { t } = useTranslation()

  const modalStyle = {
    '--legend-bg-color': config?.legend?.colors[3],
    '--legend-close-icon-button-color': config?.legend?.colors[4],
    '--legend-close-button-hover-color': config?.legend?.colors[6],
  }

  const handleCloseComplete = useCallback(() => {
    onCloseComplete()
  }, [onCloseComplete])

  return (
    <SideModal
      size="m"
      open={isOpen}
      onWppSideModalClose={onClose}
      onWppSideModalCloseComplete={handleCloseComplete}
      className={clsx(styles.modal, { [styles.actionsBorderless]: config?.legend?.colors[3] })}
      style={modalStyle}
    >
      <WppTypography slot="header" type="2xl-heading" style={{ color: config?.legend?.colors[2] }}>
        {config?.legend?.title}
      </WppTypography>
      <Flex direction="column" slot="body">
        {config?.legend?.sections.map((section, index) => (
          <LegendSection
            sectionConfig={section}
            key={index}
            isLastSection={index === config.legend.sections.length - 1}
          />
        ))}
      </Flex>
      <Flex slot="actions" gap={12} justify="end">
        <WppButton
          variant="secondary"
          onClick={onClose}
          className={clsx(styles.closeBtn, { [styles.closeBtnHover]: config?.legend?.colors[6] })}
          style={{ color: config?.legend?.colors[5] || 'var(--wpp-primary-color-500)' }}
        >
          {t('common.close')}
        </WppButton>
      </Flex>
    </SideModal>
  )
}

export const {
  showModal: showLegendSideModal,
  hideModal: hideLegendSideModal,
  useModal: useLegendSideModal,
} = createNiceModal<Props>(LegendSideModal, 'legend-side-modal')
