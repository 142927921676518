// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cHFlL{margin-left:22px;color:#505050;font-weight:400;font-size:14px;font-family:\"Noto Sans\",sans-serif;line-height:22px;white-space:pre-wrap;list-style-type:disc}.cHFlL li{margin-top:6px}.qBoyA{margin-top:6px}.NHRiN{width:12px;height:12px;margin-right:1px;margin-left:5px}.fF5om{color:#505050;font-weight:400;font-size:14px;font-family:\"Noto Sans\",sans-serif;line-height:22px}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/henkel/processPhases/pages/audience/Audience.module.scss"],"names":[],"mappings":"AAEA,OACE,gBAAA,CACA,aAAA,CACA,eAAA,CACA,cAAA,CACA,kCAAA,CACA,gBAAA,CACA,oBAAA,CACA,oBAAA,CAEA,UACE,cAAA,CAIJ,OACE,cAAA,CAGF,OACE,UAAA,CACA,WAAA,CACA,gBAAA,CACA,eAAA,CAGF,OACE,aAAA,CACA,eAAA,CACA,cAAA,CACA,kCAAA,CACA,gBAAA","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;800&display=swap');\n\n.listHenkel {\n  margin-left: 22px;\n  color: #505050;\n  font-weight: 400;\n  font-size: 14px;\n  font-family: 'Noto Sans', sans-serif;\n  line-height: 22px;\n  white-space: pre-wrap;\n  list-style-type: disc;\n\n  li {\n    margin-top: 6px;\n  }\n}\n\n.item {\n  margin-top: 6px;\n}\n\n.logo {\n  width: 12px;\n  height: 12px;\n  margin-right: 1px;\n  margin-left: 5px;\n}\n\n.itemText {\n  color: #505050;\n  font-weight: 400;\n  font-size: 14px;\n  font-family: 'Noto Sans', sans-serif;\n  line-height: 22px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"listHenkel": "cHFlL",
	"item": "qBoyA",
	"logo": "NHRiN",
	"itemText": "fF5om"
};
export default ___CSS_LOADER_EXPORT___;
