// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OUkXL{width:100%;height:calc(100svh - var(--wpp-os-header-height) - 2px);object-fit:cover;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:cover}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/netflix/NetflixProcess.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,uDAAA,CACA,gBAAA,CACA,kDAAA,CACA,2BAAA,CACA,qBAAA","sourcesContent":[".container {\n  width: 100%;\n  height: calc(100svh - var(--wpp-os-header-height) - 2px);\n  object-fit: cover;\n  background: url('./assets/background.png');\n  background-repeat: no-repeat;\n  background-size: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OUkXL"
};
export default ___CSS_LOADER_EXPORT___;
