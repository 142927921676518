// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kiyKX{fill:var(--wpp-primary-color-100, #eee8ff)}.dIgMJ{fill:var(--wpp-primary-color-200, #e2d9ff)}.VFl59{fill:var(--wpp-primary-color-300, #cab8ff)}.twiuK{fill:var(--wpp-primary-color-400, #8508e8)}.KjdaW{fill:var(--wpp-primary-color-500, #5e00b5)}.ndQV_{fill:var(--wpp-white-color, #ffffff)}", "",{"version":3,"sources":["webpack://./src/components/svg/common.module.scss"],"names":[],"mappings":"AAAA,OACE,0CAAA,CAGF,OACE,0CAAA,CAGF,OACE,0CAAA,CAGF,OACE,0CAAA,CAGF,OACE,0CAAA,CAGF,OACE,oCAAA","sourcesContent":[".fillPrimary100 {\n  fill: var(--wpp-primary-color-100, #eee8ff);\n}\n\n.fillPrimary200 {\n  fill: var(--wpp-primary-color-200, #e2d9ff);\n}\n\n.fillPrimary300 {\n  fill: var(--wpp-primary-color-300, #cab8ff);\n}\n\n.fillPrimary400 {\n  fill: var(--wpp-primary-color-400, #8508e8);\n}\n\n.fillPrimary500 {\n  fill: var(--wpp-primary-color-500, #5e00b5);\n}\n\n.fillWhite {\n  fill: var(--wpp-white-color, #ffffff);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fillPrimary100": "kiyKX",
	"fillPrimary200": "dIgMJ",
	"fillPrimary300": "VFl59",
	"fillPrimary400": "twiuK",
	"fillPrimary500": "KjdaW",
	"fillWhite": "ndQV_"
};
export default ___CSS_LOADER_EXPORT___;
