// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dGRn4{width:100%;min-height:calc(100vh - var(--wpp-os-header-height, 64px))}.bY3H0{opacity:.4;transition:opacity .2s ease-in-out}.HTRqt{opacity:0;transition:opacity .2s ease-in-out}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/axa/processScheme/ProcessScheme.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,0DAAA,CAGF,OACE,UAAA,CACA,kCAAA,CAGF,OACE,SAAA,CACA,kCAAA","sourcesContent":[".processSchemeContainer {\n  width: 100%;\n  min-height: calc(100vh - var(--wpp-os-header-height, 64px));\n}\n\n.deselectedProcessWheel {\n  opacity: 0.4;\n  transition: opacity 0.2s ease-in-out;\n}\n\n.hiddenPhaseTitle {\n  opacity: 0;\n  transition: opacity 0.2s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"processSchemeContainer": "dGRn4",
	"deselectedProcessWheel": "bY3H0",
	"hiddenPhaseTitle": "HTRqt"
};
export default ___CSS_LOADER_EXPORT___;
