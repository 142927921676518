// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wFpQ9{margin-top:-180px}.YUXk7{width:230px;padding:16px 16px 64px}.mtzQ8{background:linear-gradient(31.81deg, #6e7bff 1.51%, #4aa3ff 85.25%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.btWkq{color:#6e7bff}.uAfBC{margin-top:-100px}.OZA8D{margin-top:136px}.Y71Tb{padding:16px 16px 40px}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestleAsia/processPhases/pages/implementation/Implementation.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA,CAGF,OACE,WAAA,CACA,sBAAA,CAGF,OACE,mEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,aAAA,CAGF,OACE,iBAAA,CAGF,OACE,gBAAA,CAGF,OACE,sBAAA","sourcesContent":[".inputCardsWrapper {\n  margin-top: -180px;\n}\n\n.inputsCard {\n  width: 230px;\n  padding: 16px 16px 64px;\n}\n\n.redmillTagColor {\n  background: linear-gradient(31.81deg, #6e7bff 1.51%, #4aa3ff 85.25%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.mediaActivityTagColor {\n  color: #6e7bff;\n}\n\n.regularCard3Wrapper {\n  margin-top: -100px;\n}\n\n.regularCard4Wrapper {\n  margin-top: 136px;\n}\n\n.outputsCard {\n  padding: 16px 16px 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputCardsWrapper": "wFpQ9",
	"inputsCard": "YUXk7",
	"redmillTagColor": "mtzQ8",
	"mediaActivityTagColor": "btWkq",
	"regularCard3Wrapper": "uAfBC",
	"regularCard4Wrapper": "OZA8D",
	"outputsCard": "Y71Tb"
};
export default ___CSS_LOADER_EXPORT___;
