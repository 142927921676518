// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".K2XDN{width:calc(20% - 24px)}.j2sEu{width:80px;height:80px;background:linear-gradient(45deg, #cda457 14.58%, #ffe3a5 85.5%);border-radius:50%}._rZcQ{width:42px}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/ferreroNew/processPhases/processCard/descriptionCard/DescriptionCard.module.scss"],"names":[],"mappings":"AAAA,OACE,sBAAA,CAGF,OACE,UAAA,CACA,WAAA,CACA,gEAAA,CACA,iBAAA,CAGF,OACE,UAAA","sourcesContent":[".descriptionMinWidth {\n  width: calc(20% - 24px);\n}\n\n.iconContainer {\n  width: 80px;\n  height: 80px;\n  background: linear-gradient(45deg, #cda457 14.58%, #ffe3a5 85.5%);\n  border-radius: 50%;\n}\n\n.icon {\n  width: 42px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"descriptionMinWidth": "K2XDN",
	"iconContainer": "j2sEu",
	"icon": "_rZcQ"
};
export default ___CSS_LOADER_EXPORT___;
