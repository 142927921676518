export const No7BeautyProcessData = [
  {
    title: 'Project Kick-Off',
    description: 'Moving from Briefing into Project Initiation',
    link: '/custom/n7/project-kick-off',
  },
  {
    title: 'Strategic Response',
    description: 'A single, aligned view of the audience',
    link: '/custom/n7/strategic-response',
  },
  {
    title: 'Creative Development',
    description: 'Collaborate to create industry leading solutions',
    link: '/custom/n7/creative-development',
  },
  {
    title: 'Activation',
    description: 'Allow cross discipline review of the work',
    link: '/custom/n7/activation',
  },
  {
    title: 'Evaluation',
    description: 'A single, clear understanding and view of campaign performance',
    link: '/custom/n7/evaluation',
  },
]
