// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uXKtX{padding:16px 16px 40px}.NPIK5{background:linear-gradient(89deg, #ff5656 2.86%, #fdc46e 54.75%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestleAsia/processPhases/pages/campaignStrategicApproach/CampaignStrategicApproach.module.scss"],"names":[],"mappings":"AAAA,OACE,sBAAA,CAGF,OACE,gEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA","sourcesContent":[".inputsOutputsCard {\n  padding: 16px 16px 40px;\n}\n\n.synapseTagColor {\n  background: linear-gradient(89deg, #ff5656 2.86%, #fdc46e 54.75%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsOutputsCard": "uXKtX",
	"synapseTagColor": "NPIK5"
};
export default ___CSS_LOADER_EXPORT___;
