// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".djhnM{cursor:pointer}.DLR0q{font-weight:700;font-size:48px;font-family:var(--wpp-font-family, sans-serif)}.pgXh0{font-weight:600;font-size:16px;font-family:var(--wpp-font-family, sans-serif);font-style:normal;transition:.3s ease;will-change:opacity}", "",{"version":3,"sources":["webpack://./src/pages/processBuilder/templates/circular3/phase/Phase.module.scss"],"names":[],"mappings":"AAAA,OACE,cAAA,CAGF,OACE,eAAA,CACA,cAAA,CACA,8CAAA,CAGF,OACE,eAAA,CACA,cAAA,CACA,8CAAA,CACA,iBAAA,CACA,mBAAA,CACA,mBAAA","sourcesContent":[".phaseInteractive {\n  cursor: pointer;\n}\n\n.phaseTitle {\n  font-weight: 700;\n  font-size: 48px;\n  font-family: var(--wpp-font-family, sans-serif);\n}\n\n.overlayDescription {\n  font-weight: 600;\n  font-size: 16px;\n  font-family: var(--wpp-font-family, sans-serif);\n  font-style: normal;\n  transition: 0.3s ease;\n  will-change: opacity;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"phaseInteractive": "djhnM",
	"phaseTitle": "DLR0q",
	"overlayDescription": "pgXh0"
};
export default ___CSS_LOADER_EXPORT___;
