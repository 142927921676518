import { PhasePopupConfig } from 'pages/customProcess/components/no7Beauty/components/phasePopup/PhasePopup'

export const phasePopupsConfig: PhasePopupConfig[] = [
  {
    title: 'Project Kick-Off',
    description: 'Moving from Briefing into Project Initiation',
    list: [
      {
        title: 'The Ambition',
        items: [
          'All parties starting on the same page with one collective ambition',
          'Creating one team where everyone is galvanized behind a brief',
          'Allows us all to have something to refer back to, for reviews along the process as well as for Post Campaign Analysis (PCA) at the end',
          'Open forum for any hunches, niggles, first thoughts and considerations to supercharge the way forward',
        ],
      },
      { title: 'Inputs', items: ['Briefing template'] },
      {
        title: 'Outputs',
        items: ['Briefing template becomes our AlignedProject Charter', 'Project Timeline with all stages mapped out'],
      },
      { title: 'Key Meets', items: ['Initial Briefing Session (led by Boots)', 'Co-Creation Session (led by Pharm)'] },
    ],
    button: {
      title: 'Open Phase details',
      link: '/custom/n7/project-kick-off',
    },
  },
  {
    title: 'Strategic Response',
    description: 'A single, aligned view of the audience',
    list: [
      {
        title: 'The Ambition',
        items: [
          'A single, aligned view of the audience, the market, and the competitive environment, and the challenge that this brief is looking to overcome',
          'This translates into an integrated Strategic approach that gives every discipline the best possible opportunity to successfully crack the brief together',
        ],
      },
      {
        title: 'Inputs',
        items: [
          'Aligned supercharged Brief',
          'Audience & Marketing Insights (Pharm)',
          'Research and Insights Brief (Pharm created)',
        ],
      },
      {
        title: 'Outputs',
        items: [
          'Audience Persona',
          'Data & MeasurementFramework',
          'Strategic Approach',
          'Creative Brief',
          'Roles for comms & channels',
        ],
        listCss: { display: 'grid', gridTemplateColumns: '2fr 3fr' },
      },
      {
        title: 'Key Meets',
        items: ['Audience & Strategic Alignment Workshop (Internal)', 'Insights & Strategy Session (led by Pharm)'],
      },
    ],
    button: {
      title: 'Open Phase details',
      link: '/custom/n7/strategic-response',
    },
  },
  {
    title: 'Creative Development',
    description: 'Collaborate to create industry leading solutions',
    list: [
      {
        title: 'The Ambition',
        items: [
          'Collaborate to create industry leading solutions by fusing incredible creative thinking with innovative CX, media and PR ideas',
          'Integrate big idea, CX, media and PR plan development, to create innovative, deliverable, effective connected plan',
        ],
      },
      { title: 'Inputs', items: ['Connections Strategy Debrief', 'First draft media, data'] },
      { title: 'Outputs', items: ['Connected Plan', 'Project Timeline with all stages mapped out'] },
      {
        title: 'Key Meets',
        items: [
          'Creative Stretch the idea (Internal)',
          'Innovation Slam 2.0',
          'Integrated Connections Plan',
          'RTB2 –detailed plan responses',
        ],
      },
    ],
    button: {
      title: 'Open Phase details',
      link: '/custom/n7/creative-development',
    },
  },
  {
    title: 'Activation',
    description: 'Allow cross discipline review of the work',
    list: [
      {
        title: 'The Ambition',
        items: [
          'Allow cross discipline review of the work so that we can remain hot on the detail, and remain hot on tracking delivery against our collective ambition and objectives',
          'Enabling a strong support system so that any changes in delivery timings or output needs are managed across functions with maximum efficiency and minimum impact',
          'An energized team with regular open dialogue to keep development progressing against timelines',
        ],
      },
      { title: 'Inputs', items: ['Aligned idea'], css: { width: '46%' } },
      {
        title: 'Outputs',
        items: [
          'Media Plan',
          'Asset Delivery Tracker',
          'Production Budget Tracker capturing actuals against projected',
        ],
        css: { width: '51%' },
      },
      {
        title: 'Key Meets',
        items: [
          'Integrated Stand Ups / Weekly Working Session (led by Boots & Pharm)',
          'Cross-functional Walk Through (led by Boots & Pharm)',
          '<Monthly Walk the Work (led by Pharm & Boots)> Not project specific but will provide an extra checkpoint on output',
          '<IAD(led by Pharm & Boots)> Notproject specific but will providean extra checkpoint and governanceonplans',
        ],
      },
    ],
    button: {
      title: 'Open Phase details',
      link: '/custom/n7/activation',
    },
  },
  {
    title: 'Evaluation',
    description: 'A single, clear understanding and view of campaign performance',
    list: [
      {
        title: 'The Ambition',
        items: [
          'A single, clear understanding and view of campaign performance with insights and recommendations to take forward, backed by rationale and reason',
        ],
      },
      {
        title: 'Inputs',
        items: [
          'Results from creative, media and from Boots owned and earned channel results',
          'Boots own measurement, to create comprehensive view of performance',
        ],
      },
      {
        title: 'Outputs',
        items: [
          'Full shared PCA populated with all insight across disciplines vs the measurement framework',
          'Recommendations and insights to take forward into next campaign',
        ],
      },
      {
        title: 'Key Meets',
        items: [
          'PCA presentation(led by The Pharm & Boots)',
          'Campaign Team Operations Retrospective so we continuously improve',
        ],
      },
    ],
    button: {
      title: 'Open Phase details',
      link: '/custom/n7/evaluation',
    },
  },
]
