// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ssVOM{padding:16px}.bNVB3{margin-top:-20px}.BIvjX{background:linear-gradient(79.87deg, #e733a0 10.42%, #fa426e 31.85%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.g8c__{margin-top:-120px}.VXtoZ{margin-top:104px}.pOAAt{padding:16px 16px 64px}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestleChina/processPhases/pages/campaignBrief/CampaignBrief.module.scss"],"names":[],"mappings":"AAAA,OACE,YAAA,CAGF,OACE,gBAAA,CAGF,OACE,oEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,iBAAA,CAGF,OACE,gBAAA,CAGF,OACE,sBAAA","sourcesContent":[".inputsCard {\n  padding: 16px;\n}\n\n.inputCardsWrapper {\n  margin-top: -20px;\n}\n\n.synapseTagColor {\n  background: linear-gradient(79.87deg, #e733a0 10.42%, #fa426e 31.85%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.regularCard1Wrapper {\n  margin-top: -120px;\n}\n\n.regularCard2Wrapper {\n  margin-top: 104px;\n}\n\n.outputsCard {\n  padding: 16px 16px 64px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsCard": "ssVOM",
	"inputCardsWrapper": "bNVB3",
	"synapseTagColor": "BIvjX",
	"regularCard1Wrapper": "g8c__",
	"regularCard2Wrapper": "VXtoZ",
	"outputsCard": "pOAAt"
};
export default ___CSS_LOADER_EXPORT___;
