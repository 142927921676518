// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GcPMt{width:100%;height:100%;background:var(--wpp-grey-color-1000)}.o_2F_{cursor:pointer}.iy6Hl::part(body),.iy6Hl::part(button){color:#fff}.nSG5o::part(body),.nSG5o::part(button){--ab-primary-text-color: var(--wpp-grey-color-1000);--ab-primary-text-color-hover: var(--wpp-grey-color-1000);--ab-primary-text-color-active: var(--wpp-grey-color-1000);--ab-primary-text-color-disabled: #a2a9b0}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/netflix/pages/streamingPage/StreamingPage.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,WAAA,CACA,qCAAA,CAGF,OACE,cAAA,CAIA,wCAEE,UAAA,CAKF,wCAEE,mDAAA,CACA,yDAAA,CACA,0DAAA,CACA,yCAAA","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n  background: var(--wpp-grey-color-1000);\n}\n\n.cursor {\n  cursor: pointer;\n}\n\n.button {\n  &::part(body),\n  &::part(button) {\n    color: white;\n  }\n}\n\n.link {\n  &::part(body),\n  &::part(button) {\n    --ab-primary-text-color: var(--wpp-grey-color-1000);\n    --ab-primary-text-color-hover: var(--wpp-grey-color-1000);\n    --ab-primary-text-color-active: var(--wpp-grey-color-1000);\n    --ab-primary-text-color-disabled: #a2a9b0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "GcPMt",
	"cursor": "o_2F_",
	"button": "iy6Hl",
	"link": "nSG5o"
};
export default ___CSS_LOADER_EXPORT___;
