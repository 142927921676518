import { WppRichtextHtml } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { MouseEventHandler, useMemo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/processBuilder/templates/circular1/phase/Phase.module.scss'
import { getPieItemPath } from 'pages/processBuilder/utils'
import { FormPhase as PhaseConfig } from 'types/process/process'

export const Phase = ({
  onMouseEnter,
  onMouseLeave,
  isActive,
  rotation,
  size,
  hoverSize,
  phase,
  isEditMode,
}: {
  onMouseEnter: MouseEventHandler
  onMouseLeave: MouseEventHandler
  isActive: boolean
  rotation: number
  size: number
  hoverSize: number
  phase: PhaseConfig
  isEditMode: boolean
}) => {
  const navigate = useNavigate()
  const url = phase.innerPageEnabled && phase?.id ? `./phase/${phase?.id}` : phase?.url || ''
  const isCustomUrl = phase?.url && !phase.innerPageEnabled

  const handleClick = useCallback(() => {
    isCustomUrl ? (window.location.href = url) : navigate(url)
  }, [navigate, isCustomUrl, url])

  const { x: textX, y: textY } = useMemo(() => {
    const isCenteredX = rotation === 90 || rotation === 270
    const isCenteredY = rotation === 0 || rotation === 180

    return {
      x: rotation > 0 && rotation < 180 ? '280' : isCenteredY ? '0' : '-280',
      y: rotation > 90 && rotation < 270 ? '260' : isCenteredX ? '208' : '0',
    }
  }, [rotation])

  const isOverlayInverted = useMemo(() => rotation >= 90 && rotation < 270, [rotation])

  const overlayStart = useMemo(
    () => (phase?.overlayDescriptions && phase?.overlayDescriptions?.length < 3 ? 2 : 1),
    [phase?.overlayDescriptions],
  )

  const withLastSepatator = useMemo(
    () => phase?.overlayDescriptions && phase?.overlayDescriptions?.length > 1,
    [phase?.overlayDescriptions],
  )

  const isDescriptionBottom = useMemo(() => rotation >= 90 && rotation <= 270, [rotation])

  const isWideDescription = useMemo(() => rotation === 0 || rotation === 180, [rotation])

  const { textOrientation = 'center', textAlign = 'center' } = useMemo(
    () => ({
      textOrientation: rotation > 0 && rotation < 180 ? 'start' : rotation > 180 && rotation < 360 ? 'end' : 'center',
      textAlign: rotation > 0 && rotation < 180 ? 'left' : rotation > 180 && rotation < 360 ? 'right' : 'center',
    }),
    [rotation],
  )

  return (
    <g
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      transform={`rotate(${rotation} 641 385) translate(0, -8)`}
      className={clsx({ [styles.phaseAnimation]: !isEditMode, [styles.phaseInteractive]: url })}
      onClick={handleClick}
    >
      <g opacity={isActive ? 1 : 0}>
        <path
          d={getPieItemPath({ radius: 293, size, x: 641, y: 385 })}
          fill={phase?.colors?.[0] || '#EE3023'}
          fillOpacity="0.8"
          className={styles.pointerEventsNone}
        />

        <path
          opacity="0.8"
          d="M 514.662 176.667 C 552.87 154.608 596.211 142.995 640.329 142.995 C 684.447 142.995 727.789 154.608 765.996 176.667 L 765.182 178.077 C 727.222 156.161 684.162 144.623 640.329 144.623 C 596.496 144.623 553.436 156.161 515.476 178.077 L 514.662 176.667 Z"
          fill="url(#toolsLine)"
          transform="translate(0, -8)"
        />
        <path
          opacity="0.8"
          d="M 532.379 207.355 C 565.2 188.406 602.431 178.43 640.329 178.43 C 678.227 178.43 715.457 188.406 748.278 207.355 L 747.415 208.851 C 714.856 190.053 677.924 180.157 640.329 180.157 C 602.734 180.157 565.801 190.053 533.243 208.851 L 532.379 207.355 Z"
          fill="url(#toolsLine)"
          transform="translate(0, -3)"
        />
        <path
          opacity={withLastSepatator ? '0.8' : '0'}
          d="M 550.917 239.461 C 578.101 223.766 608.939 215.503 640.329 215.503 C 671.72 215.503 702.557 223.766 729.742 239.461 L 729.027 240.7 C 702.06 225.131 671.469 216.934 640.329 216.934 C 609.19 216.934 578.599 225.131 551.632 240.7 L 550.917 239.461 Z"
          fill="url(#toolsLine)"
          transform="translate(0, -3)"
        />
        <path
          d="M 550.917 239.461 C 578.101 223.766 608.939 215.503 640.329 215.503 C 671.72 215.503 702.557 223.766 729.742 239.461 L 729.027 240.7 C 702.06 225.131 671.469 216.934 640.329 216.934 C 609.19 216.934 578.599 225.131 551.632 240.7 L 550.917 239.461 Z"
          fill="transparent"
          transform="translate(0, 0)"
        />

        <g className={styles.pointerEventsNone}>
          {phase?.overlayDescriptions?.map((overlay, index) => (
            <text
              key={index}
              alignmentBaseline="middle"
              transform={`translate(0, ${index === 3 ? '7' : '-7'})`}
              className={styles.overlayDescription}
            >
              <textPath
                xlinkHref={`#curve${index + overlayStart}${isOverlayInverted ? '_inverted' : ''}`}
                fill={phase?.colors?.[3] || '#fff'}
                startOffset="50%"
                textAnchor="middle"
                alignmentBaseline={
                  (isOverlayInverted && index !== 3) || (!isOverlayInverted && index === 3)
                    ? 'before-edge'
                    : 'after-edge'
                }
              >
                {overlay.label}
              </textPath>
            </text>
          ))}
        </g>
      </g>
      <g>
        <foreignObject
          transform={`rotate(${-rotation} 642 93) translate(${textX} ${textY})`}
          height="200"
          width="500"
          x="395"
          y="-140"
          dominantBaseline="central"
          alignmentBaseline="central"
          textAnchor="middle"
          opacity={isActive ? '1' : '1'}
          className={styles.foreignObject}
        >
          <Flex
            className={styles.pointTextWrap}
            justify={textOrientation}
            align={isDescriptionBottom ? 'start' : 'end'}
          >
            <Flex className={styles.pointText} direction="column" align={textOrientation} gap={15}>
              <div
                className={styles.phaseTitleWrap}
                style={{
                  background: isActive ? 'rgba(255,255,255,.3)' : 'transparent',
                  order: isDescriptionBottom ? 0 : 1,
                }}
              >
                <h3
                  className={styles.phaseTitle}
                  style={{
                    color: phase?.colors?.[2] || '#fff',
                  }}
                >
                  {phase.title}
                </h3>
              </div>

              <p
                className={clsx(styles.phaseDescripton, {
                  [styles.phaseDescriptonWide]: isWideDescription,
                })}
                style={{
                  color: phase?.colors?.[2] || '#fff',
                  order: isDescriptionBottom ? 1 : 0,
                  maxWidth: isWideDescription ? '400px' : '300px',
                  textAlign,
                  opacity: isActive ? '1' : '0',
                }}
              >
                {phase.description && <WppRichtextHtml value={phase.description} />}
              </p>
            </Flex>
          </Flex>
        </foreignObject>
      </g>

      <path d={getPieItemPath({ radius: 293, size: hoverSize, x: 641, y: 385 })} fill="transparent" />

      <circle cx="642" cy="93" r={15} fill={phase?.colors?.[1] || 'white'} opacity={isActive ? 1 : 0} />

      <defs>
        <g transform={`rotate(${rotation} 641 385) translate(0, 0)`}>
          <path
            d="M 666.319 453.22 C 666.319 407.719 647.811 366.371 617.997 336.559 C 588.185 306.745 546.84 288.237 501.336 288.237 C 455.834 288.237 414.487 306.745 384.676 336.559 C 354.862 366.371 336.353 407.719 336.353 453.22"
            id="curve4_inverted"
            transform="rotate(0) translate(140 -71)"
          />
          <path
            d="M 679.475 439.41 C 679.475 386.581 657.986 338.579 623.373 303.966 C 588.761 269.354 540.759 247.865 487.93 247.865 C 435.102 247.865 387.099 269.354 352.488 303.966 C 317.874 338.579 296.386 386.581 296.386 439.41"
            id="curve3_inverted"
            transform="rotate(0) translate(155 -37)"
          />
          <path
            d="M 724.428 438.101 C 724.428 375.173 698.831 317.992 657.601 276.762 C 616.37 235.532 559.19 209.933 496.262 209.933 C 433.332 209.933 376.153 235.532 334.923 276.762 C 293.693 317.992 268.094 375.173 268.094 438.101"
            id="curve2_inverted"
            transform="rotate(0) translate(145 -40)"
          />
          <path
            d="M 741.116 848.504 C 741.116 772.85 710.34 704.108 660.774 654.541 C 611.207 604.973 542.464 574.198 466.811 574.198 C 391.157 574.198 322.414 604.973 272.847 654.541 C 223.279 704.108 192.505 772.85 192.505 848.504"
            id="curve1_inverted"
            transform="rotate(0) translate(173 -442)"
          />

          <path
            d="M 336.353 453.22 C 336.353 407.719 354.862 366.371 384.676 336.559 C 414.487 306.745 455.834 288.237 501.336 288.237 C 546.84 288.237 588.185 306.745 617.997 336.559 C 647.811 366.371 666.319 407.719 666.319 453.22"
            id="curve4"
            transform="rotate(0) translate(140 -71)"
          />
          <path
            d="M 296.386 439.41 C 296.386 386.581 317.874 338.579 352.488 303.966 C 387.099 269.354 435.102 247.865 487.93 247.865 C 540.759 247.865 588.761 269.354 623.373 303.966 C 657.986 338.579 679.475 386.581 679.475 439.41"
            id="curve3"
            transform="rotate(0) translate(155 -37)"
          />
          <path
            d="M 268.094 438.101 C 268.094 375.173 293.693 317.992 334.923 276.762 C 376.153 235.532 433.332 209.933 496.262 209.933 C 559.19 209.933 616.37 235.532 657.601 276.762 C 698.831 317.992 724.428 375.173 724.428 438.101"
            id="curve2"
            transform="rotate(0) translate(145 -40)"
          />
          <path
            d="M 192.505 848.504 C 192.505 772.85 223.279 704.108 272.847 654.541 C 322.414 604.973 391.157 574.198 466.811 574.198 C 542.464 574.198 611.207 604.973 660.774 654.541 C 710.34 704.108 741.116 772.85 741.116 848.504"
            id="curve1"
            transform="rotate(0) translate(173 -442)"
          />
        </g>
      </defs>
    </g>
  )
}
