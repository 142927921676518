// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kIiZt{padding:16px 16px 40px}.wGK58{background:linear-gradient(262deg, #ff5656 40.82%, #fdc46e 94.8%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.I589z{width:330px}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestleHealth/processPhases/pages/campaignBudgetSetting/CampaignBudgetSetting.module.scss"],"names":[],"mappings":"AAAA,OACE,sBAAA,CAGF,OACE,iEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,WAAA","sourcesContent":[".inputsOutputsCard {\n  padding: 16px 16px 40px;\n}\n\n.synapseTagColor {\n  background: linear-gradient(262deg, #ff5656 40.82%, #fdc46e 94.8%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.mainCard {\n  width: 330px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsOutputsCard": "kIiZt",
	"synapseTagColor": "wGK58",
	"mainCard": "I589z"
};
export default ___CSS_LOADER_EXPORT___;
