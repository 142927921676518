import React from 'react'
import ReactDOMClient from 'react-dom/client'
import singleSpaReact from 'single-spa-react'

import { MicroappRoot } from 'app/MicroappRoot'

const lifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: MicroappRoot,
  // TODO: Add proper error boundary
  // @ts-ignore Later
  errorBoundary() {
    // https://reactjs.org/docs/error-boundaries.html
    return null
  },
})

export const { bootstrap, mount, unmount } = lifecycles
