// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Lm59Q{max-width:var(--wpp-os-content-max-width);height:calc(100vh - var(--wpp-os-header-height));margin:0 auto}.SDunK{width:100%;max-width:1200px;height:100%;max-height:1200px}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/bp/BPProcess.module.scss"],"names":[],"mappings":"AAAA,OACE,yCAAA,CACA,gDAAA,CACA,aAAA,CAGF,OACE,UAAA,CACA,gBAAA,CACA,WAAA,CACA,iBAAA","sourcesContent":[".root {\n  max-width: var(--wpp-os-content-max-width);\n  height: calc(100vh - var(--wpp-os-header-height));\n  margin: 0 auto;\n}\n\n.svgWrapper {\n  width: 100%;\n  max-width: 1200px;\n  height: 100%;\n  max-height: 1200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Lm59Q",
	"svgWrapper": "SDunK"
};
export default ___CSS_LOADER_EXPORT___;
