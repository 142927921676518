// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jq7zx{color:#f40000}.uuZFk{padding:6px 0 8px}.uuZFk::part(typography){color:#f40000;font-weight:700;font-size:24px;line-height:32px}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/cocaColaChina/components/phaseDetails/PhaseDetails.module.scss"],"names":[],"mappings":"AAAA,OACE,aAAA,CAGF,OACE,iBAAA,CAEA,yBACE,aAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA","sourcesContent":[".step {\n  color: #f40000;\n}\n\n.discActiveTitle {\n  padding: 6px 0 8px;\n\n  &::part(typography) {\n    color: #f40000;\n    font-weight: 700;\n    font-size: 24px;\n    line-height: 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"step": "jq7zx",
	"discActiveTitle": "uuZFk"
};
export default ___CSS_LOADER_EXPORT___;
