// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GxEEK{width:233px;padding:16px}.hfhos{margin-top:-180px}.WXjl0{margin-top:-120px}.CsKoa{margin-top:72px}.wROC4{padding:16px 16px 64px}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestleAsia/processPhases/pages/integratedCommunicationsBrief/IntegratedCommunicationsBrief.module.scss"],"names":[],"mappings":"AAAA,OACE,WAAA,CACA,YAAA,CAGF,OACE,iBAAA,CAGF,OACE,iBAAA,CAGF,OACE,eAAA,CAGF,OACE,sBAAA","sourcesContent":[".inputsCardAsia {\n  width: 233px;\n  padding: 16px;\n}\n\n.inputCardsWrapper {\n  margin-top: -180px;\n}\n\n.regularCard1Wrapper {\n  margin-top: -120px;\n}\n\n.regularCard2Wrapper {\n  margin-top: 72px;\n}\n\n.outputsCard {\n  padding: 16px 16px 64px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsCardAsia": "GxEEK",
	"inputCardsWrapper": "hfhos",
	"regularCard1Wrapper": "WXjl0",
	"regularCard2Wrapper": "CsKoa",
	"outputsCard": "wROC4"
};
export default ___CSS_LOADER_EXPORT___;
