import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import styles from 'pages/phaseBuilder/legendSideModal/legendSection/LegendSection.module.scss'
import { FormLegendSection } from 'types/phase/phase'
import { getPreviewImageUrl } from 'utils/builder'

export const LegendSection = ({
  sectionConfig,
  isLastSection,
}: {
  sectionConfig: FormLegendSection
  isLastSection?: boolean
}) => {
  return (
    <div
      className={clsx(styles.section, { [styles.lastSection]: isLastSection })}
      style={{ borderColor: sectionConfig?.colors?.[1] }}
    >
      <WppTypography type="m-strong" className={styles.title} style={{ color: sectionConfig?.colors?.[0] }}>
        {sectionConfig?.title}
      </WppTypography>
      {sectionConfig?.image?.[0] && (
        <img src={getPreviewImageUrl(sectionConfig?.image)} alt="" className={styles.sectionContentImg} />
      )}
    </div>
  )
}
