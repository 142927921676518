// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OOkQA{width:100%;height:100%;background:var(--wpp-grey-color-1000)}.XMWJu{cursor:pointer}.oZBS9::part(body),.oZBS9::part(button){color:#fff}.iJWVC::part(body),.iJWVC::part(button){color:var(--wpp-grey-color-1000)}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/netflix/pages/strategyPage/StrategyPage.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,WAAA,CACA,qCAAA,CAGF,OACE,cAAA,CAIA,wCAEE,UAAA,CAKF,wCAEE,gCAAA","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n  background: var(--wpp-grey-color-1000);\n}\n\n.cursor {\n  cursor: pointer;\n}\n\n.button {\n  &::part(body),\n  &::part(button) {\n    color: white;\n  }\n}\n\n.link {\n  &::part(body),\n  &::part(button) {\n    color: var(--wpp-grey-color-1000);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OOkQA",
	"cursor": "XMWJu",
	"button": "oZBS9",
	"link": "iJWVC"
};
export default ___CSS_LOADER_EXPORT___;
