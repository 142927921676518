// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zzlsq{opacity:1;transition:opacity .25s ease}.bPRvi{transform-origin:center;transform-box:fill-box;cursor:pointer;transition:all .25s ease}.bPRvi:hover{transform:scale(1.3)}.HLVKh{cursor:default;opacity:0}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/shell/components/shellSvgStep1/ShellSvgStep1.module.scss"],"names":[],"mappings":"AAAA,OACE,SAAA,CACA,4BAAA,CAGF,OACE,uBAAA,CACA,sBAAA,CACA,cAAA,CACA,wBAAA,CAEA,aACE,oBAAA,CAIJ,OACE,cAAA,CACA,SAAA","sourcesContent":[".visible {\n  opacity: 1;\n  transition: opacity 0.25s ease;\n}\n\n.groupCard {\n  transform-origin: center;\n  transform-box: fill-box;\n  cursor: pointer;\n  transition: all 0.25s ease;\n\n  &:hover {\n    transform: scale(1.3);\n  }\n}\n\n.hidden {\n  cursor: default;\n  opacity: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"visible": "zzlsq",
	"groupCard": "bPRvi",
	"hidden": "HLVKh"
};
export default ___CSS_LOADER_EXPORT___;
