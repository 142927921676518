// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fQ1Xh{padding:16px 16px 64px}.ayemZ{background:linear-gradient(261.72deg, #ff5656 40.82%, #fdc46e 94.8%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.dyzXH{background:linear-gradient(90deg, #33bad8 86.82%, #78e820 153.94%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.LMUng{background:linear-gradient(93deg, #33bad8 -37.5%, #78e820 81.02%);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0)}.OcGVF{padding:16px 16px 40px}", "",{"version":3,"sources":["webpack://./src/pages/customProcess/components/nestleChina/processPhases/pages/campaignStrategy/CampaignStrategy.module.scss"],"names":[],"mappings":"AAAA,OACE,sBAAA,CAGF,OACE,oEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,kEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,iEAAA,CACA,4BAAA,CAAA,oBAAA,CACA,qCAAA,CAGF,OACE,sBAAA","sourcesContent":[".inputsCard {\n  padding: 16px 16px 64px;\n}\n\n.audienceDBTagColor {\n  background: linear-gradient(261.72deg, #ff5656 40.82%, #fdc46e 94.8%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.commerceDBTagColor {\n  background: linear-gradient(90deg, #33bad8 86.82%, #78e820 153.94%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.choreographTagColor {\n  background: linear-gradient(93deg, #33bad8 -37.5%, #78e820 81.02%);\n  background-clip: text;\n  -webkit-text-fill-color: transparent;\n}\n\n.outputsCard {\n  padding: 16px 16px 40px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputsCard": "fQ1Xh",
	"audienceDBTagColor": "ayemZ",
	"commerceDBTagColor": "dyzXH",
	"choreographTagColor": "LMUng",
	"outputsCard": "OcGVF"
};
export default ___CSS_LOADER_EXPORT___;
