import * as zod from 'zod'

import { DimensionUnits } from 'types/common/comon'
import { PhaseLegendVariant } from 'types/phase/phase'

const dimensionSchema = zod
  .object({
    unit: zod.nativeEnum(DimensionUnits),
    value: zod.number().optional(),
  })
  .refine(({ unit, value }) => (unit !== 'auto' ? value !== undefined : true), {
    message: 'Value is required if unit is not "auto"',
    path: ['value'],
  })

export const phaseSchema = zod.object({
  template: zod.string().min(1),
  background: zod.array(zod.any()).max(1),
  colors: zod.array(zod.string()),
  header: zod.object({
    title: zod.string().optional().nullable(),
    description: zod.string().optional().nullable(),
    logoImage: zod.array(zod.any()).max(1),
    logoDimensions: zod.object({
      width: dimensionSchema,
      height: dimensionSchema,
    }),
    background: zod.array(zod.any()).max(1),
    positionBackground: zod.string(),
    colors: zod.array(zod.string()),
    isFullWidth: zod.boolean(),
    height: zod.number(),
  }),
  card: zod.object({
    background: zod.array(zod.any()).max(1),
    contentImage: zod.array(zod.any()).max(1),
    colors: zod.array(zod.string()),
    columns: zod.array(
      zod.object({
        icon: zod.array(zod.any()).max(1),
        content: zod.string().optional().nullable(),
        id: zod.string().optional().nullable(),
      }),
    ),
    isEnabled: zod.boolean(),
    isScrollable: zod.boolean(),
  }),
  legend: zod.object({
    isEnabled: zod.boolean(),
    icon: zod.array(zod.any()).max(1),
    colors: zod.array(zod.string()),
    variant: zod.nativeEnum(PhaseLegendVariant),
    image: zod.array(zod.any()).max(1),
    title: zod.string().optional().nullable(),
    sections: zod.array(
      zod.object({
        title: zod.string().optional().nullable(),
        image: zod.array(zod.any()).max(1),
        colors: zod.array(zod.string()),
        id: zod.string().optional().nullable(),
      }),
    ),
  }),
  controls: zod.object({
    previousUrl: zod.string().optional().nullable(),
    nextUrl: zod.string().optional().nullable(),
    button: zod.object({
      title: zod.string().optional().nullable(),
      url: zod.string().optional().nullable(),
      isVisible: zod.boolean().optional().nullable(),
    }),
    colors: zod.array(zod.string()),
  }),
})
