import { RichtextChangeEventDetail } from '@platform-ui-kit/components-library'
import { WppRichtextCustomEvent } from '@platform-ui-kit/components-library/dist/types/components'
import {
  WppRichtext,
  WppIconBold,
  WppIconItalic,
  WppIconUnderline,
  WppIconStrikeThrough,
  WppIconCodeView,
  WppIconBlockquote,
  WppIconOrderedList,
  WppIconUnorderedList,
  WppIconIndentDecrease,
  WppIconIndentIncrease,
  WppIconTextAlignmentLeft,
  WppIconTextAlignmentRight,
  WppIconTextAlignmentCenter,
  WppIconTextAlignmentJustify,
  WppIconLink,
  WppIconUndo,
  WppIconRedo,
} from '@platform-ui-kit/components-library-react'
import { useCallback, forwardRef, ComponentPropsWithoutRef } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { useField } from 'hooks/form/useField'

export interface FormTextPaletteProps extends ComponentPropsWithoutRef<typeof Flex> {
  name: string
  className?: string
  placeholder?: string
}

export const FormRichText = forwardRef<HTMLWppRichtextElement, FormTextPaletteProps>(
  ({ name, className, placeholder }, ref) => {
    const {
      field: { onChange: setRichText, value: richText },
    } = useField({
      name,
    })

    const handleChange = useCallback(
      (e: WppRichtextCustomEvent<RichtextChangeEventDetail>) => {
        setRichText(e.detail.value)
      },
      [setRichText],
    )

    return (
      <>
        <WppRichtext
          onWppChange={handleChange}
          ref={ref}
          className={className}
          placeholder={placeholder}
          value={richText}
        />

        {/* TODO: remove once component library provide fix for icons tree shaker */}
        <div style={{ display: 'none' }}>
          <WppIconBold />
          <WppIconItalic />
          <WppIconUnderline />
          <WppIconStrikeThrough />
          <WppIconCodeView />
          <WppIconBlockquote />
          <WppIconOrderedList />
          <WppIconUnorderedList />
          <WppIconIndentDecrease />
          <WppIconIndentIncrease />
          <WppIconTextAlignmentLeft />
          <WppIconTextAlignmentRight />
          <WppIconTextAlignmentCenter />
          <WppIconTextAlignmentJustify />
          <WppIconLink />
          <WppIconUndo />
          <WppIconRedo />
        </div>
      </>
    )
  },
)
