import { MicroAppCustomProps } from '@wpp-open/core'

import { App } from 'app/App'
import { Root } from 'app/Root'

export const MicroappRoot = (props: MicroAppCustomProps) => (
  <Root {...props}>
    <App />
  </Root>
)
